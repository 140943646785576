import { useNavigate } from 'react-router-dom';
import { Button, TextField } from '@mui/material';
import { useState } from 'react';
import axios from 'axios';


import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';




const AddDiscount = () => {

    const navigate = useNavigate();
    const [discountName, setDiscountName] = useState('')
    const [discountNameError, setDiscountNameError] = useState(false)


    const [discount, setDiscount] = useState('')
    const [discountError, setDiscountError] = useState(false)
    const [submitData, setSubmitData] = useState(false)

    console.log('submitDatasubmitDatasubmitDatasubmitDatasubmitData=> ', submitData)

    const handleSubmit = (e) => {
        e.preventDefault();

        if (discountName == '') {
            setDiscountNameError(true)
            return
        }


        if (discount == "") {
            setDiscountError(true)
            return
        } else if (isNaN(discount)) {
            setDiscountError(true);
            return;
        }



        try {
            setSubmitData(true)
            const data = {
                name: discountName,
                type: "Percentage",
                discount: discount
            }

            axios.post(`${process.env.REACT_APP_API_BASE_URL}/discount/create`, data)
            toast.success('Discount Created Successfully!', { position: "bottom-right" });
            navigate('/discounts/list');

        }

        catch (err) {
            setSubmitData(false)
            toast.error(err?.response?.data?.message, { position: "bottom-right" });
        }


    }

    return (
        <>
            <form
                onSubmit={handleSubmit}
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    gap: '10',
                    background: '#fff',
                    padding: 15,
                    margin: 30,
                    borderRadius: 10
                }}
            >
                <div style={{ margin: 4, width: '100%' }}>
                    <p style={{ margin: 4, width: '100%', color: '#000', fontWeight: 600 }} >
                        Add Discount
                    </p>
                </div>
                <div style={{ margin: 9 }}>
                    <TextField
                        label="Discount Name"
                        name="DiscountName"
                        value={discountName}
                        onChange={(e) => {
                            setDiscountNameError(false)
                            setDiscountName(e.target.value)
                        }}
                    />
                    {discountNameError && (
                        <div style={{ color: 'red', marginLeft: '16px' }}>
                            Discount  Name is requierd
                        </div>
                    )}

                </div>

                <div style={{ margin: 9 }}>
                    <TextField
                        label="Discount"
                        value={discount}
                        name="Dicount"
                        onChange={(e) => {
                            setDiscountError(false)
                            setDiscount(e.target.value)
                        }}
                    />
                    {discountError && (
                        <div style={{ color: 'red', marginLeft: '16px' }}>
                            Please Enter Valid Discount
                        </div>
                    )}

                </div>


                <div style={{ margin: 9 }} disabled>
                    <TextField
                        label="Discount Type"
                        value="percentage"
                        name="Discount Type" />

                </div>






                <div style={{ margin: 9, width: '100%' }}>
                    <Button variant='contained' type="submit" disabled={submitData == true}>Submit</Button>
                </div>
            </form>
        </>
    )
}


export default AddDiscount;