import { useEffect, useState } from 'react';
import { Box, TextField, Card, CardContent, Typography, Button, CardHeader, Container, Divider, Grid, FormControlLabel, RadioGroup, FormControl, Radio, FormLabel } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { useLocation, useNavigate, useParams } from 'react-router';
import axios from 'axios';
import AWS from 'aws-sdk';
import './EditRider.css';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';


AWS.config.update({
  accessKeyId: 'AKIARWTYEXZGV5I6UJQJ',
  secretAccessKey: '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',
  region: 'ap-south-1',
});


const EditRider = () => {

  const navigate = useNavigate();
  const { id } = useParams();
  const s3 = new AWS.S3();



  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  // const [mobile, setMobile] = useState(null);
  const [age, setAge] = useState(null);
  const [gender, setGender] = useState('')
  const [profileImage, setProfileImage] = useState('')
  const [uanImage, setUanImage] = useState('')
  const [licenceImage, setLicenceImage] = useState('')




  const [firstNameErr, setFirstNameErr] = useState(false);
  const [lastNameErr, setLastNameErr] = useState(false)
  const [emailErr, setEmailErr] = useState(false);
  // const [mobileErr, setMobileErr] = useState(false);
  const [ageErr, setAgeErr] = useState(false);
  const [ageDataErr, setAgeDataErr] = useState(false);

  const [genderErr, setGenderErr] = useState(false)
  const [profileImageErr, setProfileImageErr] = useState(false)
  const [uanImageErr, setUanImageErr] = useState(false)
  const [licenceImageErr, setLicenceImageErr] = useState(false)

  const [submitButton, setSumitButton] = useState(false)

  const handleImageChange = async (e) => {
    setProfileImageErr(false)

    if (e.target.files[0]?.name) {
      const params = {
        Bucket: 'suribucket-v2',
        Key: `${Date.now()}.${e?.target?.files[0]?.name}`,
        Body: e?.target?.files[0],
        ACL: 'public-read-write'
      };

      try {
        const { Location } = await s3.upload(params).promise();
        setProfileImage(Location);
      } catch (error) {
        toast.error(error, { position: "bottom-right" });
      }
    }
  };



  const handleUanNumber = async (e) => {
    setUanImageErr(false)

    if (e.target.files[0]?.name) {
      const params = {
        Bucket: 'suribucket-v2',
        Key: `${Date.now()}.${e?.target?.files[0]?.name}`,
        Body: e?.target?.files[0],
        ACL: 'public-read-write'
      };

      try {
        const { Location } = await s3.upload(params).promise();
        setUanImage(Location);
      } catch (error) {
        toast.error(error, { position: "bottom-right" });
      }
    }
  };

  const handleLicence = async (e) => {
    setLicenceImageErr(false)

    if (e.target.files[0]?.name) {
      const params = {
        Bucket: 'suribucket-v2',
        Key: `${Date.now()}.${e?.target?.files[0]?.name}`,
        Body: e?.target?.files[0],
        ACL: 'public-read-write'
      };

      try {
        const { Location } = await s3.upload(params).promise();
        setLicenceImage(Location);
      } catch (error) {
        toast.error(error, { position: "bottom-right" });
      }
    }
  };








  const RiderData = async () => {
    try {
      let data = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/driver/${id}`)
      setFirstName(data?.data?.firstName)
      setLastName(data?.data?.lastName)
      setEmail(data?.data?.email)
      // setMobile(data?.data?.mobile)
      setAge(data?.data?.age)
      setGender(data?.data?.gender)
      setProfileImage(data?.data?.profileImage)
      setUanImage(data?.data?.uan)
      setLicenceImage(data?.data?.licence)


    } catch (error) {
      console.log("Error in rider data is = ", error)
    }
  }

  useEffect(() => {
    RiderData();
  }, [])

  const handleEdit = async () => {
    try {
      if (firstName == '') {
        setFirstNameErr(true);
        return;
      }
      if (lastName == '') {
        setLastNameErr(true);
        return;
      }

      if (email === '') {
        setEmailErr(true);
        return;
      } else {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
          setEmailErr(true);
          return;
        }
      }

      if (gender == '') {
        setGenderErr(true);
        return;
      }

      // if (mobile == '') {
      //   setMobileErr(true);
      //   return;
      // }
      // else if (!/^\d{10}$/.test(mobile)) {
      //   setMobileErr(true)
      // }

      if (age === '') {
        setAgeErr(true);
        return;
      } else {
        const ageRegex = /^[0-9]+$/;
        if (!ageRegex.test(age)) {
          setAgeDataErr(true);
          return;
        }
      }
      if (profileImage == '') {
        setProfileImageErr(true);
        return;
      }
      if (uanImage == '') {
        setUanImageErr(true);
        return;
      }

      if (licenceImage == '') {
        setLicenceImageErr(true);
        return;
      }

      setSumitButton(true)
      await axios.patch(`${process.env.REACT_APP_API_BASE_URL}/driver/${id}`, {
        firstName: firstName, lastName: lastName, profileImage: profileImage, age: age,
        licence: licenceImage, uan: uanImage, email: email, gender: gender
      });

      toast.success('Rider Updated Successfully!', { position: "bottom-right" });
      navigate('/riders/pendingDriver');
    }
    catch (error) {
      setSumitButton(false)
      toast.error(error?.response?.data?.message, { position: "bottom-right" });
    }
  }

  return (
    <>

      <Helmet>
        <title>Edit Rider</title>
      </Helmet>

      <Container maxWidth="lg" sx={{ mt: 2 }}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={2}
        >
          <Grid item xs={12}>
            <Card>
              <CardHeader title="Edit Rider" />
              <Divider />
              <CardContent>
                <Box
                  component="form"
                  sx={{
                    '& .MuiTextField-root': { m: 1, width: '25ch' }
                  }}
                  noValidate
                  autoComplete="off"
                >


                  <div className='Top-Box-Section'>
                    <div>
                      <TextField
                        id="outlined-required"
                        label="First Name"
                        name="firstName"
                        value={firstName ? firstName : ''}
                        onChange={(e) => {
                          setFirstName(e.target.value)
                          setFirstNameErr(false)
                        }}
                      />
                      {firstNameErr && (
                        <span style={{ color: 'red', display: 'flex', paddingTop: '10px' }}>
                          FirstName is required.
                        </span>
                      )}
                    </div>

                    <div>
                      <TextField
                        id="outlined-required"
                        label="Last Name"
                        name="lastName"
                        value={lastName ? lastName : ''}
                        onChange={(e) => {
                          setLastName(e.target.value)
                          setLastNameErr(false)
                        }}
                      />
                      {lastNameErr && (
                        <span style={{ color: 'red', display: 'flex', paddingTop: '10px' }}>
                          LastName is required.
                        </span>
                      )}
                    </div>



                    <div>
                      <TextField
                        id="outlined-required"
                        label="email"
                        name="email"
                        value={email ? email : ''}
                        onChange={(e) => {
                          setEmail(e.target.value)
                          setEmailErr(false)
                        }}
                      />
                      {emailErr && (
                        <span style={{ color: 'red', display: 'flex', paddingTop: '10px' }}>
                          Please Enter Valid Email
                        </span>
                      )}
                    </div>

                    {/* <div>
                    <TextField
                      id="outlined-required"
                      label="Mobile"
                      name="mobile"
                      value={mobile ? mobile : ''}
                      onChange={(e) => {
                        setMobile(e.target.value)
                        setMobileErr(false)
                      }}
                    />
                    {mobileErr && (
                      <span style={{ color: 'red', display: 'flex', paddingTop: '10px' }}>
                        Please Enter Valid Mobile Number
                      </span>
                    )}
                  </div> */}

                    <div>
                      <TextField
                        id="outlined-required"
                        label="Age"
                        name="age"
                        value={age ? age : ''}
                        onChange={(e) => {
                          setAge(e.target.value)
                          setAgeErr(false)
                          setAgeDataErr(false)
                        }}
                      />
                      {ageErr && (
                        <span style={{ color: 'red', display: 'flex', paddingTop: '10px' }}>
                          Age is required.
                        </span>
                      )}
                      {ageDataErr && (
                        <span style={{ color: 'red', display: 'flex', paddingTop: '10px' }}>
                          Age Should Be Number
                        </span>
                      )}

                    </div>
                  </div>

                  <FormControl component="fieldset" style={{ margin: 8 }}>
                    <FormLabel component="legend">Gender</FormLabel>
                    <RadioGroup
                      row
                      aria-label="gender"
                      name="gender"
                      value={gender ? gender : ''}
                      onChange={(e) => {
                        setGender(e.target.value)
                        setGenderErr(false)
                      }}
                    >
                      <FormControlLabel
                        value="female"
                        control={<Radio />}
                        label="Female"
                      />
                      <FormControlLabel value="male" control={<Radio />} label="Male" />
                      <FormControlLabel value="other" control={<Radio />} label="Other" />
                    </RadioGroup>
                    {genderErr && (
                      <span style={{ color: 'red', display: 'flex', paddingTop: '10px' }}>
                        Gender is required.
                      </span>
                    )}
                  </FormControl>

                  <div className='image-section-box'>
                    <div style={{ margin: 12, textAlign: 'center',    minHeight:300, position:'relative', minWidth:200,}}>
                      <span> PROFILE IMAGE </span>
                      <div>
                        {profileImage && <img className='Edit-Rider-Image' src={profileImage} alt="Image" width='20%' height='10%' />
                        }
                        <input
                          className='choose-file1'
                          type="file"
                          accept="image/*"
                          onChange={handleImageChange}
                        />
                      </div>
                      {profileImageErr && (
                        <span
                          style={{
                            color: 'red',
                            display: 'flex',
                            paddingTop: '10px'
                          }}
                        >
                          Profile Image is Required
                        </span>
                      )}
                    </div>

                    <div style={{ margin: 12, textAlign: 'center',  minHeight:300, position:'relative', minWidth:200, }}>
                      <span> UAN </span>
                      <div>

                        {uanImage && <img className='Edit-Rider-Image' src={uanImage} alt="Image" width='20%' height='10%' />
                        }
                        <input
                          className='choose-file1'
                          type="file"
                          accept="image/*"
                          onChange={handleUanNumber}
                        />
                      </div>
                      {uanImageErr && (
                        <span
                          style={{
                            color: 'red',
                            display: 'flex',
                            paddingTop: '10px'
                          }}
                        >
                          Required Field
                        </span>
                      )}
                    </div>



                    <div style={{ margin: 12, textAlign: 'center', minHeight:300, position:'relative',minWidth:200, }}>
                      <span> Licence</span>
                      <div>
                        {licenceImage && <img className='Edit-Rider-Image' src={licenceImage} alt="Image" width='20%' height='10%' />
                        }
                        <input
                          className='choose-file1'
                          type="file"
                          accept="image/*"
                          onChange={handleLicence}
                        />
                      </div>
                      {licenceImageErr && (
                        <span
                          style={{
                            color: 'red',
                            display: 'flex',
                            paddingTop: '10px'
                          }}
                        >
                          Required Field
                        </span>
                      )}
                    </div>

                  </div>



                  <div style={{ display: 'flex', marginLeft: '10px', gap: '20px', fontSize: '30px', marginTop: '20px' }}>
                    <Button className='SubmitBtn11' onClick={handleEdit} disabled={submitButton === true}>Submit</Button>
                    <Button className='SubmitBtn11' onClick={() => navigate('/riders/pendingDriver')}>Back to Riders</Button>

                  </div>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default EditRider;
