import { FC, ChangeEvent, useState, useEffect } from 'react';
import {
  Tooltip, Divider, Box, FormControl, InputLabel, Card, Checkbox, IconButton, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, TableContainer, Select, MenuItem, Typography, useTheme, CardHeader
} from '@mui/material';

import { CryptoOrder, CryptoOrderStatus } from 'src/models/crypto_order';

import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Button from '@mui/material/Button';
const moment = require('moment');

import { socket } from 'src/App';

import 'react-toastify/dist/ReactToastify.css';
import { toast, ToastContainer } from 'react-toastify';
import './OrderListTable.css';

interface CategoryListTableProps {
  className?: string;
}

interface Filters {
  status?: CryptoOrderStatus;
}

const applyFilters = (
  cryptoOrders: any[],
  filters: Filters
): CryptoOrder[] => {
  return cryptoOrders.filter((cryptoOrder) => {
    let matches = true;

    // if (filters.status && cryptoOrder.status !== filters.status) {
    //   matches = false;
    // }

    return matches;
  });
};

const applyPagination = (
  cryptoOrders: CryptoOrder[],
  page: number,
  limit: number
): CryptoOrder[] => {
  return cryptoOrders.slice(page * limit, page * limit + limit);
};

const OrdersTable: FC<CategoryListTableProps> = ({ }) => {
  const [cryptoOrders, setcryptoOrdersPage] = useState([]);
  const [selectedCryptoOrders, setSelectedCryptoOrders] = useState<string[]>([]);
  const selectedBulkActions = selectedCryptoOrders.length > 0;
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(5);
  const [filters, setFilters] = useState<Filters>({
    status: null
  });




  const handlePageChange = (event: any, newPage: number): void => {
    setPage(newPage);
  };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value));
  };

  const filteredCryptoOrders = applyFilters(cryptoOrders, filters);
  const paginatedCryptoOrders = applyPagination(
    filteredCryptoOrders,
    page,
    limit
  );
  const selectedSomeCryptoOrders =
    selectedCryptoOrders.length > 0 &&
    selectedCryptoOrders.length < cryptoOrders.length;
  const selectedAllCryptoOrders =
    selectedCryptoOrders.length === cryptoOrders.length;
  const theme = useTheme();
  const navigate = useNavigate();


  const [todayData, setTodayData] = useState(false);

  const handleNavigateToOrders = () => {
    setTodayData(false)
    fetchData();
    navigate('/orders')
  };

  const [statusFilter, setStatusFilter] = useState('all');
  const [orders, setOrders] = useState([])

  const fetchData = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/order/getall/?filter={}`);
      setcryptoOrdersPage(response?.data)
      setOrders(response?.data)
    } catch (err) {
      console.log("Error:", err);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);




  useEffect(() => {
    console.log("Status filter:", statusFilter);

    if (statusFilter === 'all') {
      setcryptoOrdersPage(orders);
    } else {
      let data = orders.filter((item) => item?.status === statusFilter);
      setcryptoOrdersPage(data);
    }
  }, [statusFilter]);




  console.log('data >>>>>>>>>>>>>>>>>>>>>>>>>>= ', cryptoOrders)



  const updateFilter = (e) => {
    console.log(e.target.value)
    setStatusFilter(e.target.value);
  };


  const handleTodayOrderData = async () => {
    try {

      setTodayData(true)
      let curentDate = new Date();
      curentDate.setDate(curentDate.getDate());
      let startDate = curentDate.setHours(0, 0, 0, 0);
      let endDate = curentDate.setHours(23, 59, 59, 999);
      // console.log("startDate = > ", startDate, "endDate = ", endDate)
      const apiUrl = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/order/getall/?filter={"where":{"createdAt":{"$gte": "${startDate}","$lte": "${endDate}"}}}`)
      setcryptoOrdersPage(apiUrl?.data)
    } catch (error) {
      toast.error(error?.response?.data?.message, { position: "bottom-right" });
    }
  }

  return (
    <Card sx={{ m: 3 }}>

      <CardHeader
        className="section-Header"
        title={todayData ? "Today Orders" : "All Orders"}
        action={
          <Box className="sectin-1-right" >
            <div className='orderBtnBox'>

              <Button onClick={handleTodayOrderData} className='OrderBtn' style={{ display: todayData ? 'none' : 'block' }}>

                Today's Order</Button>

              <Button className='OrderBtn' onClick={handleNavigateToOrders} style={{ display: todayData == false ? 'none' : 'block' }} >All Orders</Button>


            </div>
            {
              todayData == false ? (
                <FormControl fullWidth variant="outlined" className='select-box'>
                  <InputLabel className='StatusClass'>Status</InputLabel>

                  <Select
                    variant="filled"
                    className="select-item"
                    value={statusFilter}
                    name="filter"
                    onChange={updateFilter}
                    sx={{
                      border: '0px solid red',
                      outline: 'none',
                      boxShadow: 'none'
                    }}>

                    <MenuItem value={'all'}>All</MenuItem>
                    <MenuItem value={'Pending'}>Pending</MenuItem>
                    <MenuItem value={'Accepted'}>Accepted</MenuItem>
                    <MenuItem value={'Delivered'}>Delivered</MenuItem>
                    <MenuItem value={'Preparing'}>Preparing</MenuItem>
                    <MenuItem value={'Picked Up'}>Picked Up</MenuItem>
                    <MenuItem value={'Cancelled'}>Cancelled</MenuItem>
                    <MenuItem value={'Rejected'}>Rejected</MenuItem>
                    <MenuItem value={'Ready to pickup'}>Ready to pickup</MenuItem>


                  </Select>
                </FormControl>
              )
                :
                null
            }

          </Box>
        }
      />







      <Divider />
      <TableContainer className='table-box'>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Order Id</TableCell>
              <TableCell>Username</TableCell>
              <TableCell>Location/Address</TableCell>
              <TableCell>Price</TableCell>
              <TableCell>Quantity</TableCell>
              <TableCell align='center'>Date</TableCell>
              <TableCell align="right">Status</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {/* {paginatedCryptoOrders.map((cryptoOrder: any) => {
              const isCryptoOrderSelected = selectedCryptoOrders.includes(
                cryptoOrder.id
              ); */}

            {
              todayData == true ? (

                paginatedCryptoOrders.map((cryptoOrder: any) => {
                  const isCryptoOrderSelected = selectedCryptoOrders.includes(cryptoOrder.id);
                  return (
                    <TableRow
                      hover
                      key={cryptoOrder.id}
                      selected={isCryptoOrderSelected}
                    >

                      <TableCell>
                        <Typography
                          variant="body1"
                          fontWeight="bold"
                          color="text.primary"
                          gutterBottom
                          noWrap
                        >
                          {cryptoOrder?.orderId}
                        </Typography>

                      </TableCell>

                      <TableCell>
                        <Typography
                          variant="body1"
                          fontWeight="bold"
                          color="text.primary"
                          gutterBottom
                          noWrap
                        >

                          {cryptoOrder?.userId?.firstName}
                        </Typography>

                      </TableCell>


                      <TableCell>
                        <Typography className='table-data-1'
                          variant="body1"
                          fontWeight="bold"
                          color="text.primary"
                          gutterBottom
                          noWrap >
                          {cryptoOrder?.address}
                        </Typography>

                      </TableCell>


                      <TableCell>
                        <Typography
                          variant="body1"
                          fontWeight="bold"
                          color="text.primary"
                          gutterBottom
                          noWrap
                        >
                          {cryptoOrder?.totalPrice}
                        </Typography>



                      </TableCell>
                      <TableCell align='center'>
                        <Typography
                          variant="body1"
                          fontWeight="bold"
                          color="text.primary"
                          gutterBottom
                          noWrap
                        >
                          {cryptoOrder?.totalQuantity}
                        </Typography>
                      </TableCell>


                      <TableCell align='center'>
                        <Typography
                          variant="body1"
                          fontWeight="bold"
                          color="text.primary"
                          gutterBottom
                          noWrap
                        >
                          {moment(cryptoOrder?.createdAt).format('DD-MM-YYYY')}
                        </Typography>
                      </TableCell>

                      <TableCell align="right">
                        {cryptoOrder.status}
                        {/* Active */}
                      </TableCell>
                      <TableCell align="center">


                        {/* {cryptoOrder?.status === 'Preparing' && buttonVisible && (
                          <Button
                            disabled={readyData == true}
                            variant="contained"
                            color="success"
                            onClick={() =>
                              handleAvailableChange(
                                cryptoOrder?._id,
                                cryptoOrder?.status
                              )
                            }
                          >
                            Ready
                          </Button>
                        )} */}

                        {/* {
                          cryptoOrder?.driverAssigned == true || cryptoOrder?.status == "Cancelled" ? (
                            <Tooltip title="Edit Order" arrow>
                              <IconButton
                                sx={{
                                  '&:hover': {
                                    background: theme.colors.primary.lighter
                                  },
                                  color: theme.palette.primary.main
                                }}
                                color="inherit"
                                size="small"
                                disabled={true}
                              >

                                <EditTwoToneIcon fontSize="small" onClick={() => navigate(`/Orders/edit-order/${cryptoOrder?._id}?totalPrice=${encodeURIComponent(cryptoOrder?.totalPrice)}`)} />
                              </IconButton>
                            </Tooltip>
                          )
                            :
                            (
                              <Tooltip title="Edit Order" arrow>
                                <IconButton
                                  sx={{
                                    '&:hover': {
                                      background: theme.colors.primary.lighter
                                    },
                                    color: theme.palette.primary.main
                                  }}
                                  color="inherit"
                                  size="small"
                                >
                                  <EditTwoToneIcon fontSize="small" onClick={() => navigate(`/Orders/edit-order/${cryptoOrder?._id}?totalPrice=${encodeURIComponent(cryptoOrder?.totalPrice)}`)} />
                                </IconButton>
                              </Tooltip>
                            )
                        } */}




                        <Tooltip title="Edit Order" arrow>
                          <IconButton
                            sx={{
                              '&:hover': {
                                background: theme.colors.primary.lighter
                              },
                              color: theme.palette.primary.main
                            }}
                            color="inherit"
                            size="small"

                          >
                            <VisibilityIcon fontSize="small"
                              onClick={() => {
                                const encodedOrder = encodeURIComponent(JSON.stringify(cryptoOrder));
                                navigate(`/Orders/view/${cryptoOrder?._id}`)
                              }


                              } />
                          </IconButton>
                        </Tooltip>



                      </TableCell>
                    </TableRow>
                  );
                })

              ) :
                (
                  paginatedCryptoOrders.map((cryptoOrder: any) => {
                    const isCryptoOrderSelected = selectedCryptoOrders.includes(cryptoOrder.id);
                    return (
                      <TableRow
                        hover
                        key={cryptoOrder.id}
                        selected={isCryptoOrderSelected}
                      >

                        <TableCell>
                          <Typography
                            variant="body1"
                            fontWeight="bold"
                            color="text.primary"
                            gutterBottom
                            noWrap
                          >
                            {cryptoOrder?.orderId}
                          </Typography>

                        </TableCell>


                        <TableCell>
                          <Typography
                            variant="body1"
                            fontWeight="bold"
                            color="text.primary"
                            gutterBottom
                            noWrap
                          >
                            {cryptoOrder?.userId?.firstName}
                          </Typography>

                        </TableCell>

                        <TableCell>
                          <Typography className='table-data-1'
                            variant="body1"
                            fontWeight="bold"
                            color="text.primary"
                            gutterBottom
                            noWrap >
                            {cryptoOrder?.address}
                          </Typography>

                        </TableCell>


                        <TableCell>
                          <Typography
                            variant="body1"
                            fontWeight="bold"
                            color="text.primary"
                            gutterBottom
                            noWrap
                          >
                            {cryptoOrder?.totalPrice}
                          </Typography>



                        </TableCell>
                        <TableCell align='center'>
                          <Typography
                            variant="body1"
                            fontWeight="bold"
                            color="text.primary"
                            gutterBottom
                            noWrap
                          >
                            {cryptoOrder?.totalQuantity}
                          </Typography>
                        </TableCell>


                        <TableCell align='center'>
                          <Typography
                            variant="body1"
                            fontWeight="bold"
                            color="text.primary"
                            gutterBottom
                            noWrap
                          >
                            {moment(cryptoOrder?.createdAt).format('DD-MM-YYYY')}
                          </Typography>
                        </TableCell>

                        <TableCell align="right">
                          {cryptoOrder.status}
                          {/* Active */}
                        </TableCell>
                        <TableCell align="center">


                          {/* {cryptoOrder?.status === 'Preparing' && buttonVisible && (
                            <Button
                              disabled={readyData == true}
                              variant="contained"
                              color="success"
                              onClick={() =>
                                handleAvailableChange(
                                  cryptoOrder?._id,
                                  cryptoOrder?.status
                                )
                              }
                            >
                              Ready
                            </Button>
                          )}

                          {
                            cryptoOrder?.driverAssigned == true || cryptoOrder?.status == "Cancelled" ? (
                              <Tooltip title="Edit Order" arrow>
                                <IconButton
                                  sx={{
                                    '&:hover': {
                                      background: theme.colors.primary.lighter
                                    },
                                    color: theme.palette.primary.main
                                  }}
                                  color="inherit"
                                  size="small"
                                  disabled={true}
                                >

                                  <EditTwoToneIcon fontSize="small" onClick={() => navigate(`/Orders/edit-order/${cryptoOrder?._id}?totalPrice=${encodeURIComponent(cryptoOrder?.totalPrice)}`)} />
                                </IconButton>
                              </Tooltip>
                            )
                              :
                              (
                                <Tooltip title="Edit Order" arrow>
                                  <IconButton
                                    sx={{
                                      '&:hover': {
                                        background: theme.colors.primary.lighter
                                      },
                                      color: theme.palette.primary.main
                                    }}
                                    color="inherit"
                                    size="small"
                                  >
                                    <EditTwoToneIcon fontSize="small" onClick={() => navigate(`/Orders/edit-order/${cryptoOrder?._id}?totalPrice = ${encodeURIComponent(cryptoOrder?.totalPrice)} `)} />
                                  </IconButton>
                                </Tooltip>
                              )
                          } */}




                          <Tooltip title="View Order" arrow>
                            <IconButton
                              sx={{
                                '&:hover': {
                                  background: theme.colors.primary.lighter
                                },
                                color: theme.palette.primary.main
                              }}
                              color="inherit"
                              size="small"

                            >
                              <VisibilityIcon fontSize="small"
                                onClick={() => { navigate(`/Orders/view/${cryptoOrder?._id}`) }


                                } />
                            </IconButton>
                          </Tooltip>



                        </TableCell>
                      </TableRow>
                    );
                  })
                )
            }


          </TableBody>
        </Table>
      </TableContainer>
      <Box p={2}>
        <TablePagination
          component="div"
          count={filteredCryptoOrders.length}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 25, 30]}
        />
      </Box>
    </Card >
  );
};

export default OrdersTable;
