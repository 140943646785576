


import { FC, ChangeEvent, useState, useEffect } from 'react';
import {
  Button, Tooltip, Divider, Box, FormControl, InputLabel, Card, Checkbox, IconButton, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, TableContainer, Select, MenuItem, Typography, useTheme, CardHeader
} from '@mui/material';

import axios from 'axios';
import { CryptoOrder, CryptoOrderStatus } from 'src/models/crypto_order';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import { useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import './RiderList.css';

interface CategoryListTableProps {
  className?: string;
}

interface Filters {
  status?: CryptoOrderStatus;
}


const applyFilters = (
  cryptoOrders: any[],
  filters: Filters
): CryptoOrder[] => {
  return cryptoOrders.filter((cryptoOrder) => {
    let matches = true;
    return matches;
  });
};

// const applyPagination = (
//   cryptoOrders: CryptoOrder[],
//   page: number,
//   limit: number
// ): CryptoOrder[] => {
//   return cryptoOrders.slice(page * limit, page * limit + limit);
// };


const applyPagination = (
  cryptoOrders: CryptoOrder[],
  page: number,
  limit: number
): CryptoOrder[] => {
  if (cryptoOrders?.length <= limit) {
    return cryptoOrders;
  } else {
    return cryptoOrders.slice(page * limit, page * limit + limit);
  }
};



const RejectedDriver: FC<CategoryListTableProps> = ({ }) => {
  const [cryptoOrders, setcryptoOrdersPage] = useState([]);
  const [selectedCryptoOrders, setSelectedCryptoOrders] = useState<string[]>([]);
  const selectedBulkActions = selectedCryptoOrders.length > 0;
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(5);
  const [filters, setFilters] = useState<Filters>({
    status: null
  });





  const handlePageChange = (event: any, newPage: number): void => {
    setPage(newPage);
  };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value));
  };


  const filteredCryptoOrders = applyFilters(cryptoOrders, filters);
  const paginatedCryptoOrders = applyPagination(
    filteredCryptoOrders,
    page,
    limit
  );
  const selectedSomeCryptoOrders =
    selectedCryptoOrders.length > 0 &&
    selectedCryptoOrders.length < cryptoOrders.length;
  const selectedAllCryptoOrders =
    selectedCryptoOrders.length === cryptoOrders.length;
  const theme = useTheme();
  const navigate = useNavigate();
  // const [deleteButton, setDeleteButton] = useState(false)

  const fetchUserData = async () => {
    try {
      const status = {
        "status": "Approved"
      }
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/driver/getall?filter={"where":{"status":"Rejected"}}`);
      setcryptoOrdersPage(response?.data)
    } catch (err) {
      toast.error(err?.response?.data?.message, { position: "bottom-right" });
    }
  };

  useEffect(() => {
    fetchUserData();
  }, []);


  const handleDeleteData = async (id: any) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_BASE_URL}/driver/${id}`
      );
      toast.success('Rider Deleted Successfully!', { position: "bottom-right" });
      fetchUserData();
    } catch (error) {
      toast.error(error?.response?.data?.message, { position: "bottom-right" });
    }
  }

  return (
    <Card className='TopMainBox'>
      <Box sx={{ width: '100%', m: 2 }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Button className='Button-approved-1' onClick={() => navigate('/riders/approvedDriver')}> Approved Driver</Button>
          <Button className='Button-approved-1' onClick={() => navigate('/riders/pendingDriver')}> Requested Driver</Button>
          <Button className='Button-approved-1' onClick={() => navigate('/riders/rejectedDriver')}> Rejected Driver</Button>
        </Box>
      </Box >

      <CardHeader
        action={
          <Box width={150}>
            <FormControl fullWidth variant="outlined">
            </FormControl>
          </Box>
        }
        title="Rejected Riders"
      />

      <Divider />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>FirstName</TableCell>
              <TableCell>Age</TableCell>
              <TableCell>Mobile Number</TableCell>
              <TableCell>Status</TableCell>
              <TableCell align="right">Actions</TableCell>

            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedCryptoOrders.map((cryptoOrder: any) => {
              const isCryptoOrderSelected = selectedCryptoOrders.includes(
                cryptoOrder.id
              );
              return (
                <TableRow
                  hover
                  key={cryptoOrder.id}
                  selected={isCryptoOrderSelected}
                >
                  <TableCell>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      gutterBottom
                      noWrap
                    >
                      {cryptoOrder?.firstName}
                    </Typography>

                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      gutterBottom
                      noWrap
                    >

                      {cryptoOrder?.age}
                    </Typography>

                  </TableCell>

                  <TableCell>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      gutterBottom
                      noWrap
                    >

                      {cryptoOrder?.mobile}
                    </Typography>

                  </TableCell>

                  <TableCell>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      gutterBottom
                      noWrap
                    >

                      {cryptoOrder?.status}
                    </Typography>

                  </TableCell>


                  <TableCell align="right">
                    <Tooltip title="Edit Order" arrow>
                      <IconButton
                        sx={{
                          '&:hover': {
                            background: theme.colors.primary.lighter
                          },
                          color: theme.palette.primary.main
                        }}
                        color="inherit"
                        size="small"
                      >
                        <EditTwoToneIcon fontSize="small" onClick={() => navigate(`/Riders/edit-rider/${cryptoOrder?._id}`)} />
                      </IconButton>
                    </Tooltip>

                    {/* Delete Icon */}
                    <Tooltip title="Delete Order" arrow>
                      <IconButton
                        sx={{
                          '&:hover': { background: theme.colors.error.lighter },
                          color: theme.palette.error.main
                        }}
                        color="inherit"
                        size="small"
                      >

                        <DeleteTwoToneIcon fontSize="small" onClick={() => handleDeleteData(cryptoOrder?._id)} />

                      </IconButton>
                    </Tooltip>

                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Box p={2}>
        <TablePagination
          component="div"
          count={filteredCryptoOrders.length}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 25, 30]}
        />
      </Box>
    </Card>
  );
};

export default RejectedDriver;
