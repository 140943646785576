import { useLocation } from 'react-router';
import moment from "moment";
import { Button } from '@mui/material';
import { useNavigate } from 'react-router';
import './ViewDish.css';


const ViewDishTable = () => {
    const navigate = useNavigate();

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const encodedDish = queryParams.get('dish');
    let dish;
    console.log("DIshhhhhhhhhhhhh= ", dish)
    try {
        dish = JSON.parse(encodedDish);
    } catch (error) {
        console.log('Error parsing JSON:', error);
    }
    console.log("dish  = ", dish)

    return (


        <div className='detail-page-section'>
            <p className='info-text'>Dish</p>

            <div className='Section-new'>

                <div className='Main-box-1'>

                    <div className='order-info-box'>
                        <p className='order-text-1'>  Dish Price</p>
                        <p className='order-text-2'>{dish?.price}</p>
                    </div>

                    <div className='order-info-box order-info-box-right'>
                        <p className='order-text-1'>Short Description</p>
                        <p className='order-text-2'>{dish?.shortDescription}</p>
                    </div>

                </div>


                <div className='Main-box-1'>

                    <div className='order-info-box'>
                        <p className='order-text-1'>  Description</p>
                        <p className='order-text-2'>{dish?.description}</p>
                    </div>

                    <div className='order-info-box order-info-box-right'>
                        <p className='order-text-1'>Sku  </p>
                        <p className='order-text-2'>{dish?.sku}</p>
                    </div>

                </div>


                <div className='Main-box-1'>

                    <div className='order-info-box'>
                        <p className='order-text-1'>  Special Price </p>
                        <p className='order-text-2'>{dish?.specialPrice} </p>
                    </div>

{/* 
                    <div className='order-info-box order-info-box-right'>
                        <p className='order-text-1'>Gender</p>
                        <p className='order-text-2'></p>
                    </div> */}

                </div>



                <div className='Main-box-1'>

                    <div className='order-info-box'>
                        <p className='order-text-1'> Status</p>
                        <p className='order-text-2'> {dish?.status}</p>
                    </div>


                    <div className='order-info-box order-info-box-right'>
                        <p className='order-text-1'>Title</p>
                        <p className='order-text-2'>{dish?.title}</p>
                    </div>

                </div>


                {/* <div className='Main-box-1'>

                    <div className='order-info-box'>
                        <p className='order-text-1'>  Date </p>
                        <p className='order-text-2'> {moment(dish?.createdAt).format('YYYY-MM-DD')}</p>
                    </div>


                    <div className='order-info-box order-info-box-right'>
                        <p className='order-text-1'>Time</p>
                        <p className='order-text-2'>{moment(dish?.createdAt).format('HH:mm:ss')}</p>
                    </div>

                </div> */}




                <div className='Main-box-1'>

                    <div className='order-info-box'>
                        <p className='order-text-1'>  Dish Image</p>
                        <img className='food-image22'

                            src={dish?.images}
                            alt={``}
                            style={{ width: '50px', height: '50px', marginRight: '5px' }} />
                    </div>




                </div>
            </div>


            <div>
                <Button className='back-btn-2' onClick={() => navigate('/dishes')}>
                    Back to Dishes
                </Button>
            </div>
        </div>
    );


}
export default ViewDishTable;









