import { Box, Button, Card, CardContent, CardHeader, Container, Divider, Grid, TextField } from '@mui/material';
import axios from 'axios';
import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const EditCategory = () => {

    const navigate = useNavigate();
    const { id } = useParams();
    const location = useLocation();
    const initialTitle = new URLSearchParams(location.search).get('title') || '';
    const [title, setTitle] = useState(initialTitle);
    const [titleErr, setTitleErr] = useState(false);

    const handleEdit = async () => {
        try {
            if (title == '') {
                setTitleErr(true);
                return;
            }
            const response = await axios.patch(`${process.env.REACT_APP_API_BASE_URL}/category/${id}`, {
                title: title
            });
            toast.success('Category Added Successfully!', { position: "bottom-right" });

            navigate('/categories');
        } catch (error) {
            toast.error(error?.response?.data?.message, {
                position: "bottom-right",
            });
        }
    }
    return (
        <>
            <Helmet>
                <title>Edit Category</title>
            </Helmet>

            <Container maxWidth="lg" sx={{ mt: 2 }}>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="stretch"
                    spacing={2}
                >
                    <Grid item xs={12}>
                        <Card>
                            <CardHeader title="Edit Category" />
                            <Divider />
                            <CardContent>
                                <Box
                                    component="form"
                                    sx={{
                                        '& .MuiTextField-root': { m: 1, width: '25ch' }
                                    }}
                                    noValidate
                                    autoComplete="off"
                                >
                                    <div>
                                        <TextField

                                            id="outlined-required"
                                            label="Title"
                                            name="title"
                                            value={title}
                                            onChange={(e) => {
                                                setTitleErr(false);
                                                setTitle(e.target.value);
                                            }}
                                        />
                                        {titleErr && (
                                            <div style={{ color: 'red', marginLeft: '16px' }}>Title is requierd</div>
                                        )}
                                    </div>

                                    <div>
                                        <Button onClick={handleEdit}>Submit</Button>
                                        <Button onClick={() => navigate('/categories')}>Back to Categories</Button>

                                    </div>
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
}


export default EditCategory;
