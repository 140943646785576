import { useEffect, useState } from 'react';
import {
  Box, TextField, Card, CardContent, Button, CardHeader, Container, Divider, Grid, Select, MenuItem, FormControl, InputLabel
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { useLocation, useNavigate, useParams } from 'react-router';
import axios from 'axios';

import { socket } from 'src/App';


import 'react-toastify/dist/ReactToastify.css';
import { toast, ToastContainer } from 'react-toastify';

// import { Socket } from 'socket.io-client';
// import io from 'socket.io-client';

const EditOrder = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation();


  const [ridersList, setridersList] = useState([]);
  const [totalPriceErr, settotalPriceErr] = useState(false);
  const [toggleAssignRider, settoggleAssignRider] = useState(false);
  const [selectedItem, setSelectedItem] = useState('');
  const [err, setErr] = useState(false);

  const [userData, setUserData] = useState('')
  const [orderStatus, setOrderStatus] = useState('')
  const [orderId, setOrderId] = useState('');



  const orderData = async () => {
    try {
      const data = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/orderget/${id}`)
      console.log("data >>> ", data?.data?.status)
      setOrderStatus(data?.data?.status);
      setOrderId(data?.data?.orderId)

    } catch (error) {
      toast.error(error?.response?.data?.message, { position: "bottom-right" });
    }
  }

  useEffect(() => {
    orderData()
  }, [])

  console.log("orderId =  ", orderId)

  /* -------------------Order Cancel--------------------  */

  const handleOrderCancel = async () => {
    try {

      let deleteData = await axios.patch(`${process.env.REACT_APP_API_BASE_URL}/order/${id}`, { status: "Cancelled" });


      try {

        let orderDeleted = {
          orderId: id,
          type: 'orderCancelled',
          message: `Your Order No.:${orderId} is Cancel`,
          userId: deleteData?.data?.data?.userId
        };


        const response = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/notification/create`,
          orderDeleted
        );


        socket.emit('orderCancelUser', { data: response?.data?.data });


        navigate('/Orders')
        toast.success('Order Cancel Successfully!', { position: "bottom-right" });
      } catch (error) {
        toast.error(error?.response?.data?.message, { position: "bottom-right" });
      }
    } catch (error) {
      toast.error(error?.response?.data?.message, { position: "bottom-right" });
    }
  }



  const fetchRiders = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/driver/getall/?filter={}`
      );
      console.log("response =       ------------------- ", response)
      setridersList(response?.data);
    } catch (err) {
      toast.error(err?.response?.data?.message, { position: "bottom-right" });
    }
  };

  useEffect(() => {
    fetchRiders();
  }, []);


  const [accept, setAccept] = useState(false)
  const handleAccept = async () => {
    setAccept(true)


    await axios.patch(
      `${process.env.REACT_APP_API_BASE_URL}/order/${id}`, {
      status: "Accepted"
    }).then(async (res) => {
      setUserData(res?.data?.data?.userId)


      /*Notification Create */

      let orderAccept = {
        driverId: res?.data?.data?.driverId,
        orderId: id,
        type: 'orderAccepted',
        message: `Order No.:${orderId} Accepted`,
        userId: res?.data?.data?.userId
      };

      if (orderStatus != 'Accepted') {
        try {

          const response = await axios.post(
            `${process.env.REACT_APP_API_BASE_URL}/notification/create`,
            orderAccept
          );

          toast.success('Order Accept Successfully!', { position: "bottom-right" });
          socket.emit('orderAccepted', { data: response?.data?.data })

        } catch (error) {
          toast.error(error?.response?.data?.message, { position: "bottom-right" });
        }
      }



    }).catch((err) => {
      setAccept(false);
      toast.error(err?.response?.data?.message, { position: "bottom-right" });
    })

    settoggleAssignRider(!toggleAssignRider);
  };
  const handleSelectChange = (event: any) => {
    setErr(false)
    setSelectedItem(event.target.value);
  };
  const [submitData, setSubmitData] = useState(false)

  const handleDriver = async () => {
    try {
      setSubmitData(true)

      if (selectedItem == '') {
        setErr(true)
      }




      try {
        const driverData = await axios.patch(`${process.env.REACT_APP_API_BASE_URL}/driver/${selectedItem}`, { occupied: true });

        console.log("resp ================ ", driverData?.data?.data)

        /* Notification Create of Rider  */
        try {
          let finalData = {
            driverId: selectedItem,
            orderId: id,
            type: 'Rider assigned',
            message: `Rider name ${driverData?.data?.data?.firstName} is assigned`,
          };


          const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/notification/create`, finalData);
          socket.emit('riderAssign', { data: response?.data?.data })


        } catch (error) {
          console.log("error is = ", error)
        }
        /*Notification Create Rider End */


        await axios.patch(`${process.env.REACT_APP_API_BASE_URL}/order/${id}`, { status: "Preparing" }).then(async (res) => {

          let finalData = {
            driverId: selectedItem,
            orderId: id,
            type: 'OrderPreparing',
            userId: userData,
            message: `Your Order No.:${orderId} is Preparing`
          };

          const preparingData = await axios.post(
            `${process.env.REACT_APP_API_BASE_URL}/notification/create`,
            finalData
          );

          // Rider Assign
          toast.success('Order is preparing!', { position: "bottom-right" });
          socket.emit('orderPreparing', { data: preparingData?.data?.data })


        }).catch((err) => {
          setSubmitData(false)
        })


        if (driverData) {
          const response1 = await axios.patch(`${process.env.REACT_APP_API_BASE_URL}/order/${id}`, {
            driverAssigned: true, driverId: selectedItem
          });

          toast.success('Driver Assigned Succesfully!', { position: "bottom-right" });
        }

        navigate('/Orders');
      } catch (error) {
        setSubmitData(false)
      }



    } catch (err) {
      setSubmitData(false)
    }
  };
  return (
    <>
      <Helmet>
        <title>Edit Order</title>
      </Helmet>

      <Container maxWidth="lg" sx={{ mt: 2 }}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={2}
        >
          <Grid item xs={12}>
            <Card>
              <CardHeader title="Edit Order" />
              <Divider />
              <CardContent>
                <Box
                  component="form"
                  sx={{
                    '& .MuiTextField-root': { m: 1, width: '25ch' }
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <div>
                    <Button size="small" onClick={handleAccept} disabled={accept === true}>
                      {orderStatus == "Accepted" ? "Driver Select" : "Accept"}
                    </Button>
                    <Button onClick={() => navigate('/Orders')}>
                      Back To Order
                    </Button>

                    <Button onClick={handleOrderCancel}>
                      Cancel Order
                    </Button>
                  </div>
                </Box>

                <div style={{ marginTop: '25px' }}>
                  {toggleAssignRider && (
                    <>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Assign Driver
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Assign Driver"
                          // name="categoryId"
                          value={selectedItem}
                          onChange={handleSelectChange}
                          style={{ width: '150px' }}
                        >
                          {ridersList?.map(
                            (item: any) =>
                              !item?.occupied && (
                                <MenuItem value={item?._id} key={item?._id}>
                                  {item?.firstName}
                                </MenuItem>
                              )
                          )}
                        </Select>
                      </FormControl>

                      <Button
                        disabled={submitData === true}
                        size="small"
                        onClick={handleDriver}
                        sx={{ mt: 3 }}
                      >
                        Submit
                      </Button>
                    </>
                  )}

                  {totalPriceErr && (
                    <span
                      style={{
                        color: 'red',
                        display: 'flex',
                        paddingTop: '10px'
                      }}
                    >
                      Total Price is required.
                    </span>
                  )}
                </div>

                {err && (
                  <span style={{ color: 'red', display: 'flex', paddingTop: '10px' }}>
                    Required Filed
                  </span>
                )}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default EditOrder;
