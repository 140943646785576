import { useState } from 'react';
import { Box, TextField, Card, CardContent, Typography, Button, CardHeader, Container, Divider, Grid } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { useLocation, useNavigate, useParams } from 'react-router';
import axios from 'axios';


import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import './EditUser.css';

const EditUser = () => {

    const navigate = useNavigate();
    const { id } = useParams();
    const location = useLocation();

    const initialFirstName = new URLSearchParams(location.search).get('firstName') || '';
    const initialEmail = new URLSearchParams(location.search).get('email') || '';
    const initialMobile = new URLSearchParams(location.search).get('mobile') || '';


    const [firstName, setfirstName] = useState(initialFirstName);
    const [email, setEmail] = useState(initialEmail);
    const [mobile, setMobile] = useState(initialMobile);


    const [firstNameErr, setfirstNameErr] = useState(false);
    const [emailErr, setEmailErr] = useState(false);
    const [emailError, setEmailError] = useState(false)



    function validateEmail(email) {
        const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        return emailPattern.test(email);
    }
    const [submitData, setSubmitData] = useState(false)


    const handleEdit = async () => {
        try {
            if (firstName == '') {
                setfirstNameErr(true);
                return;
            }
            if (email == '') {
                setEmailErr(true);
                return;
            }

            if (!validateEmail(email)) {
                setEmailError(true)
                return;
            }


            setSubmitData(true)
            await axios.patch(`${process.env.REACT_APP_API_BASE_URL}/user/${id}`, {
                firstName: firstName, email: email
            });
            toast.success('User Update Successfully!', { position: "bottom-right" });
            navigate('/users');
        } catch (error) {
            setSubmitData(false);
            toast.error(error?.response?.data?.message, { position: "bottom-right" });
        }
    }

    return (
        <>
            <Helmet>
                <title>Edit User</title>
            </Helmet>

            <Container maxWidth="lg" sx={{ mt: 2 }}>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="stretch"
                    spacing={2}
                >
                    <Grid item xs={12}>
                        <Card>
                            <CardHeader title="Edit User" />
                            <Divider />
                            <CardContent>
                                <Box className="UpperBox"
                                    component="form"
                                    sx={{
                                        '& .MuiTextField-root': { m: 1, width: '25ch' }
                                    }}
                                    noValidate
                                    autoComplete="off"
                                >
                                    <div>
                                        <TextField
                                            id="outlined-required"
                                            label="Name"
                                            name="name"
                                            value={firstName == 'undefined' ? '' : firstName}
                                            onChange={(e) => setfirstName(e.target.value)}
                                        />
                                        {firstNameErr && (
                                            <span style={{ color: 'red', display: 'flex', paddingTop: '10px' }}>
                                                Name is required.
                                            </span>
                                        )}
                                    </div>
                                    <div>
                                        <TextField
                                            id="outlined-required"
                                            label="Email"
                                            name="email"
                                            value={email == 'undefined' ? '' : email}

                                            onChange={
                                                (e) => {
                                                    setEmail(e.target.value)
                                                    setEmailErr(false)
                                                    setEmailError(false)
                                                }}
                                        />
                                        {emailErr && (
                                            <span style={{ color: 'red', display: 'flex', paddingTop: '10px' }}>
                                                Email is required
                                            </span>
                                        )}

                                        {emailError && (
                                            <span style={{ color: 'red', display: 'flex', paddingTop: '10px' }}>
                                                Please Provide Valid Email
                                            </span>
                                        )}

                                    </div>

                                    <div>
                                        <TextField
                                            id="outlined-required"
                                            label="Mobile"
                                            name="mobile"
                                            value={mobile}
                                            disabled
                                        />
                                    </div>

                                </Box>
                                <div className="BtnBox">
                                    <Button className="Btn1" onClick={handleEdit} disabled={submitData == true}>Submit</Button>
                                    <Button className="Btn1" onClick={() => navigate('/users')}>Back to Users</Button>
                                </div>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
};

export default EditUser;
