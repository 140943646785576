// import { useState, useEffect } from 'react';
// import {
//   Tooltip,
//   Box,
//   IconButton,
//   TextareaAutosize,
//   TableCell,

//   TableRow,
//   TableContainer,
//   Select,
//   Typography,
//   useTheme,
//   Button,
//   Modal
// } from '@mui/material';
// import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
// import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
// import { useNavigate } from 'react-router-dom';
// import axios from 'axios';

// import VisibilityIcon from '@mui/icons-material/Visibility';

// import 'react-toastify/dist/ReactToastify.css';
// import { toast, ToastContainer } from 'react-toastify';
// import { App, socket } from 'src/App';
// const style = {
//   position: 'absolute' as 'absolute',
//   top: '50%',
//   left: '50%',
//   transform: 'translate(-50%, -50%)',
//   width: 480,
//   bgcolor: 'background.paper',
//   border: '2px solid #000',
//   boxShadow: 24,
//   p: 4,
// };

// const PendingDriver = ({ cryptoOrder, isCryptoOrderSelected, fetchData }) => {
//   const theme = useTheme();
//   const navigate = useNavigate();
//   const [open, setOpen] = useState(false);
//   const handleOpen = () => setOpen(true);
//   const handleClose = () => setOpen(false);
//   const [reportMessage, setreportMessage] = useState('');

//   const [approveButton, setApproveButton] = useState(false)
//   const [rejectButton, setRejectButton] = useState(false);
//   const [deleteButton, setDeleteButton] = useState(false)

//   const handleDelete = async (id: any) => {
//     try {
//       // setDeleteButton(true)
//       await axios.delete(`${process.env.REACT_APP_API_BASE_URL}/driver/${id}`);
//       toast.success('Rider Deleted Successfully!', { position: "bottom-right" });
//       fetchData();
//     } catch (error) {
//       toast.error(error?.response?.data?.message, { position: "bottom-right" });
//     }
//   };

//   const handleApprove = async (e) => {
//     try {
//       e.preventDefault();
//       setApproveButton(true)
//       const response = await axios.patch(
//         `${process.env.REACT_APP_API_BASE_URL}/driver/${cryptoOrder?._id}`, {
//         status: 'Approved'
//       }
//       );

//       socket.emit("driverApproved", { data: response?.data?.data })
//       toast.success('Rider Approved Successfully!', { position: "bottom-right" });
//       fetchData();
//     } catch (error) {
//       toast.error(error?.response?.data?.message, { position: "bottom-right" });

//     }
//   }

//   const handleRejectReason = async (e) => {
//     try {
//       e.preventDefault();
//       setRejectButton(true)
//       await axios.patch(`${process.env.REACT_APP_API_BASE_URL}/driver/${cryptoOrder?._id}`, { status: 'Rejected' });
//       toast.success('Rider Rejected Successfully!', { position: "bottom-right" });
//       setreportMessage('')
//       fetchData();
//       handleClose();
//     } catch (error) {
//       toast.error(error?.response?.data?.message, { position: "bottom-right" });
//     }
//   }

//   return (
//     <>
//       <TableRow hover key={cryptoOrder.id} selected={isCryptoOrderSelected}>
//         <TableCell>
//           <Typography
//             variant="body1"
//             fontWeight="bold"
//             color="text.primary"
//             gutterBottom
//             noWrap
//           >
//             {cryptoOrder?.firstName}
//           </Typography>
//         </TableCell>

//         <TableCell>
//           <Typography
//             variant="body1"
//             fontWeight="bold"
//             color="text.primary"
//             gutterBottom
//             noWrap
//           >
//             {cryptoOrder?.age}
//           </Typography>
//         </TableCell>

//         <TableCell>
//           <Typography
//             variant="body1"
//             fontWeight="bold"
//             color="text.primary"
//             gutterBottom
//             noWrap
//           >
//             {cryptoOrder?.mobile}
//           </Typography>
//         </TableCell>

//         <TableCell align="right">
//           {cryptoOrder.status}
//           {/* Active */}
//         </TableCell>
//         <TableCell align="right">
//           <Tooltip title="Edit Order" arrow>
//             <IconButton
//               sx={{
//                 '&:hover': {
//                   background: theme.colors.primary.lighter
//                 },
//                 color: theme.palette.primary.main
//               }}
//               color="inherit"
//               size="small"

//             >
//               <VisibilityIcon fontSize="small"
//                 onClick={() => {
//                   const encodedOrder = encodeURIComponent(JSON.stringify(cryptoOrder));
//                   navigate(`/riders/view/${cryptoOrder?._id}?rider=${encodedOrder}`)
//                 }


//                 } />
//             </IconButton>
//           </Tooltip>

//           <Tooltip title="Edit Rider" arrow>
//             <IconButton
//               sx={{
//                 '&:hover': {
//                   background: theme.colors.primary.lighter
//                 },
//                 color: theme.palette.primary.main
//               }}
//               color="inherit"
//               size="small"
//             >
//               <EditTwoToneIcon
//                 fontSize="small"
//                 onClick={() =>
//                   navigate(
//                     `/Riders/edit-rider/${cryptoOrder?._id
//                     }?firstName=${encodeURIComponent(
//                       cryptoOrder?.firstName
//                     )}&mobile=${encodeURIComponent(cryptoOrder?.mobile)}`
//                   )
//                 }
//               />
//             </IconButton>
//           </Tooltip>

//           {/* Delete Icon */}
//           {/* <Tooltip title="Delete Rider" arrow>
//             <IconButton
//               sx={{
//                 '&:hover': {
//                   background: theme.colors.error.lighter
//                 },
//                 color: theme.palette.error.main
//               }}
//               color="inherit"
//               size="small"
//             >
//               <DeleteTwoToneIcon
//                 fontSize="small"
//                 onClick={() => handleDelete(cryptoOrder?._id)}
//               />
//             </IconButton>
//           </Tooltip> */}


//           <Button size="small" variant="contained" color="success" onClick={handleApprove} disabled={approveButton === true}>Approved</Button>
//           <Button size="small" variant="contained" color="warning" onClick={handleOpen} style={{ marginLeft: '10px' }} disabled={rejectButton === true}>Reject</Button>
//           <Button size="small" variant="contained" color="error" onClick={() => handleDelete(cryptoOrder?._id)} style={{ marginLeft: '10px' }}>Delete</Button>


//         </TableCell>


//       </TableRow>

//       <Modal
//         open={open}
//         onClose={handleClose}
//         aria-labelledby="modal-modal-title"
//         aria-describedby="modal-modal-description"
//       >
//         <Box sx={style}>
//           <Typography id="modal-modal-title" variant="h3" component="h2">
//             Kindly specify your reasons.
//           </Typography>
//           <hr />
//           <form onSubmit={handleRejectReason}>
//             <TextareaAutosize
//               aria-label="minimum height"
//               minRows={3}
//               placeholder="Give reason here."
//               style={{ width: 370 }}
//               value={reportMessage}
//               onChange={(e) => setreportMessage(e.target.value)}
//             />

//             <Button type="submit" sx={{ mt: 2 }} variant="contained">
//               Yes
//             </Button>

//           </form>
//         </Box>
//       </Modal>
//     </>
//   );
// };

// export default PendingDriver;


import { FC, ChangeEvent, useState, useEffect } from 'react';
import {
  Button, Tooltip, Divider, Box, FormControl, InputLabel, Card, Checkbox, IconButton, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, TableContainer, Select, MenuItem, Typography, useTheme, CardHeader
} from '@mui/material';

import axios from 'axios';
import { CryptoOrder, CryptoOrderStatus } from 'src/models/crypto_order';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import { useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import './RiderList.css';


import { socket, App } from 'src/App';

interface CategoryListTableProps {
  className?: string;
}

interface Filters {
  status?: CryptoOrderStatus;
}


const applyFilters = (
  cryptoOrders: any[],
  filters: Filters
): CryptoOrder[] => {
  return cryptoOrders.filter((cryptoOrder) => {
    let matches = true;
    return matches;
  });
};

// const applyPagination = (
//   cryptoOrders: CryptoOrder[],
//   page: number,
//   limit: number
// ): CryptoOrder[] => {
//   return cryptoOrders.slice(page * limit, page * limit + limit);
// };

const applyPagination = (
  cryptoOrders: CryptoOrder[],
  page: number,
  limit: number
): CryptoOrder[] => {
  if (cryptoOrders?.length <= limit) {
    return cryptoOrders;
  } else {
    return cryptoOrders.slice(page * limit, page * limit + limit);
  }
};




const PendingDriver: FC<CategoryListTableProps> = ({ }) => {
  const [cryptoOrders, setcryptoOrdersPage] = useState([]);
  const [selectedCryptoOrders, setSelectedCryptoOrders] = useState<string[]>([]);
  const selectedBulkActions = selectedCryptoOrders.length > 0;
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(5);
  const [filters, setFilters] = useState<Filters>({
    status: null
  });





  const handlePageChange = (event: any, newPage: number): void => {
    setPage(newPage);
  };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value));
  };


  const filteredCryptoOrders = applyFilters(cryptoOrders, filters);
  const paginatedCryptoOrders = applyPagination(
    filteredCryptoOrders,
    page,
    limit
  );
  const selectedSomeCryptoOrders =
    selectedCryptoOrders.length > 0 &&
    selectedCryptoOrders.length < cryptoOrders.length;
  const selectedAllCryptoOrders =
    selectedCryptoOrders.length === cryptoOrders.length;
  const theme = useTheme();
  const navigate = useNavigate();
  // const [deleteButton, setDeleteButton] = useState(false)

  const fetchUserData = async () => {
    try {
      const status = {
        "status": "Approved"
      }
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/driver/getall?filter={"where":{"status":"Pending"}}`);
      setcryptoOrdersPage(response?.data)
    } catch (err) {
      toast.error(err?.response?.data?.message, { position: "bottom-right" });
    }
  };

  useEffect(() => {
    fetchUserData();
  }, []);


  const handleDeleteData = async (id: any) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_BASE_URL}/driver/${id}`
      );
      toast.success('Rider Deleted Successfully!', { position: "bottom-right" });
      fetchUserData();
    } catch (error) {
      toast.error(error?.response?.data?.message, { position: "bottom-right" });
    }
  }

  const handleApprove = async (id) => {
    try {
      console.log("id = ", id)

      const response = await axios.patch(`${process.env.REACT_APP_API_BASE_URL}/driver/${id}`, { status: "Approved" });
      console.log('response =>>>>>>..... ', response?.data?.data)
      fetchUserData();
      socket.emit("driverApproved", { data: response?.data?.data })

      // let finalData = {
      //   driverId: id,
      //   orderId: id,
      //   type: 'Rider assigned',
      //   // message: `Rider name ${driverData?.data?.data?.firstName} is assigned`,
      //   message: `You have assigned an order,order ID ${handleAssign?.data?.orderId}  `,

      // };


      // const data = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/notification/create`, finalData);
      // socket.emit('driverApproved', { data: data?.data?.data })


      toast.success('Rider Approved Successfully!', { position: "bottom-right" });

    } catch (err) {
      toast.error(err?.response?.data?.message, { position: "bottom-right" });
    }
  }

  const handleRejected = async (id) => {
    try {
      console.log("id = ", id)

      const response = await axios.patch(`${process.env.REACT_APP_API_BASE_URL}/driver/${id}`, { status: "Rejected" });
      fetchUserData()
      toast.success('Rider Rejected Successfully!', { position: "bottom-right" });

    } catch (err) {
      toast.error(err?.response?.data?.message, { position: "bottom-right" });
    }
  }

  return (
    <Card className='TopMainBox'>
      <Box sx={{ width: '100%', m: 2 }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Button className='Button-approved-1' onClick={() => navigate('/riders/approvedDriver')}> Approved Driver</Button>
          <Button className='Button-approved-1' onClick={() => navigate('/riders/pendingDriver')}> Requested Driver</Button>
          <Button className='Button-approved-1' onClick={() => navigate('/riders/rejectedDriver')}> Rejected Driver</Button>
        </Box>
      </Box >

      <CardHeader
        action={
          <Box width={150}>
            <FormControl fullWidth variant="outlined">
            </FormControl>
          </Box>
        }
        title="Pending Riders"
      />

      <Divider />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>FirstName</TableCell>
              <TableCell>Age</TableCell>
              <TableCell>Mobile Number</TableCell>
              <TableCell>Status</TableCell>
              <TableCell align="right">Actions</TableCell>

            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedCryptoOrders.map((cryptoOrder: any) => {
              const isCryptoOrderSelected = selectedCryptoOrders.includes(
                cryptoOrder.id
              );
              return (
                <TableRow
                  hover
                  key={cryptoOrder.id}
                  selected={isCryptoOrderSelected}
                >
                  <TableCell>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      gutterBottom
                      noWrap
                    >
                      {cryptoOrder?.firstName}
                    </Typography>

                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      gutterBottom
                      noWrap
                    >

                      {cryptoOrder?.age}
                    </Typography>

                  </TableCell>

                  <TableCell>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      gutterBottom
                      noWrap
                    >

                      {cryptoOrder?.mobile}
                    </Typography>

                  </TableCell>

                  <TableCell>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      gutterBottom
                      noWrap
                    >

                      {cryptoOrder?.status}
                    </Typography>

                  </TableCell>


                  <TableCell align="right">
                    <Tooltip title="Edit Order" arrow>
                      <IconButton
                        sx={{
                          '&:hover': {
                            background: theme.colors.primary.lighter
                          },
                          color: theme.palette.primary.main
                        }}
                        color="inherit"
                        size="small"
                      >
                        <EditTwoToneIcon fontSize="small" onClick={() => navigate(`/Riders/edit-rider/${cryptoOrder?._id}`)} />
                      </IconButton>
                    </Tooltip>

                    {/* Delete Icon */}
                    <Tooltip title="Delete Order" arrow>
                      <IconButton
                        sx={{
                          '&:hover': { background: theme.colors.error.lighter },
                          color: theme.palette.error.main
                        }}
                        color="inherit"
                        size="small"
                      >

                        <DeleteTwoToneIcon fontSize="small" onClick={() => handleDeleteData(cryptoOrder?._id)} />

                      </IconButton>
                    </Tooltip>

                    <Button onClick={() => { handleApprove(cryptoOrder?._id) }}>  Approved</Button>
                    <Button onClick={() => { handleRejected(cryptoOrder?._id) }}> Rejected</Button>


                  </TableCell>

                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Box p={2}>
        <TablePagination
          component="div"
          count={filteredCryptoOrders.length}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 25, 30]}
        />
      </Box>
    </Card>
  );
};

export default PendingDriver;
