import { useState } from 'react';
import { useParams } from 'react-router';
import 'react-toastify/dist/ReactToastify.css';
import { toast, ToastContainer } from 'react-toastify';
import { Button, FormControl, InputLabel, MenuItem, MenuList, Select } from '@mui/material';
import { useNavigate } from 'react-router';
import './ViewTable.css';
import { useEffect } from 'react';
import axios from 'axios';
import { socket } from 'src/App';

const ViewTable = () => {
    const navigate = useNavigate();




    const { id } = useParams();
    const [viewData, setViewData] = useState({})
    const [dishData, setDishData] = useState([])



    const HandleViewData = async () => {
        try {
            const data = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/orderget/${id}`)
            setViewData(data?.data)

        } catch (error) {
            console.log("error =>>>>>>>>. ", error)
        }
    }
    useEffect(() => {
        HandleViewData()
    }, [])



    const [ridersList, setridersList] = useState([]);
    const [err, setErr] = useState(true);
    const [selectedItem, setSelectedItem] = useState('');

    const SelectDriverHandle = (event) => {
        setErr(false)
        setSelectedItem(event.target.value);
    };

    const fetchDrivers = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_BASE_URL}/driver/getall/?filter={}`
            );
            setridersList(response?.data);
        } catch (err) {
            toast.error(err?.response?.data?.message, { position: "bottom-right" });
        }
    };

    useEffect(() => {
        fetchDrivers();
    }, []);

    const [orderStatus, setOrderStatus] = useState('')


    const handleOrderData = async () => {
        try {
            const data = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/orderget/${id}`)
            setOrderStatus(data?.data?.status);
        } catch (error) {
            toast.error(error?.response?.data?.message, { position: "bottom-right" });
        }
    }

    useEffect(() => {
        handleOrderData()
    })

    const [acceptOrder, setAcceptOrder] = useState(false)
    const [orderStatusManage, setOrderStatusManage] = useState('')

    const AcceptOrder = async () => {
        /* Order Accept */
        try {
            setAcceptOrder(true)
            let data = await axios.patch(`${process.env.REACT_APP_API_BASE_URL}/order/${id}`, { status: "Accepted" });
            setOrderStatus("Accepted");
            try {
                let createOrderAcceptNotification = {
                    orderId: id,
                    type: 'orderAccepted',
                    message: `Order No. ${data?.data?.data?.orderId} Accepted`,
                    userId: data?.data?.data?.userId
                };

                const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/notification/create`, createOrderAcceptNotification);
                toast.success('Order Accept Successfully!', { position: "bottom-right" });
                socket.emit('orderAccepted', { data: response?.data?.data })

            }
            catch (error) {
                setAcceptOrder(false)
                toast.error(error?.response?.data?.message, { position: "bottom-right" });
            }
        }
        catch (error) {
            setAcceptOrder(false)
            // settoggleAssignRider(!toggleAssignRider);
            toast.error(error?.response?.data?.message, { position: "bottom-right" });
        }
    };

    const [orderCancelButton, setOrderCancelButton] = useState(false)

    const handleOrderCancel = async () => {

        try {
            setOrderCancelButton(true)
            let data = await axios.patch(`${process.env.REACT_APP_API_BASE_URL}/order/${id}`, { status: "Cancelled" });
            setOrderStatus("Cancelled")
            try {
                let createOrderAcceptNotification = {
                    orderId: id,
                    type: 'orderCancelled',
                    message: `Your Order No. ${data?.data?.data?.orderId} is Cancel`,
                    userId: data?.data?.data?.userId
                };



                const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/notification/create`, createOrderAcceptNotification);
                socket.emit('orderCancelUser', { data: response?.data?.data });
                toast.success('Order Cancelled Successfully!', { position: "bottom-right" });
            }
            catch (error) {
                setOrderCancelButton(false)
                toast.error(error?.response?.data?.message, { position: "bottom-right" });
            }
        } catch (error) {
            setOrderCancelButton(false)
            toast.error(error?.response?.data?.message, { position: "bottom-right" });
        }
    }


    const [assignDriver, setAssinDriver] = useState(false)
    const [assignData, setAssignData] = useState({});


    const handleAssignDriver = async () => {
        try {
            setAssinDriver(true)
            try {
                let createOrderAcceptNotification = {
                    orderId: id,
                    type: 'orderCancelled',
                    message: `Your Order No. ${data?.data?.data?.orderId} is Cancel`,
                    userId: data?.data?.data?.userId
                };



                const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/notification/create`, createOrderAcceptNotification);
                socket.emit('orderCancelUser', { data: response?.data?.data });
                toast.success('Order Cancelled Successfully!', { position: "bottom-right" });
            }
            catch (error) {
                setOrderCancelButton(false)
                toast.error(error?.response?.data?.message, { position: "bottom-right" });
            }


            const driverData = await axios.patch(`${process.env.REACT_APP_API_BASE_URL}/driver/${selectedItem}`, { occupied: true });

            /* Notification Create of Rider  */
            const handleAssign = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/orderget/${id}`)

            setAssignData(handleAssign?.data?.driverId)
            try {
                let finalData = {
                    driverId: selectedItem,
                    orderId: id,
                    type: 'Rider assigned',
                    // message: `Rider name ${driverData?.data?.data?.firstName} is assigned`,
                    message: `You have assigned an order,order ID ${handleAssign?.data?.orderId}  `,

                };


                const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/notification/create`, finalData);
                socket.emit('riderAssign', { data: response?.data?.data })


            } catch (error) {
                console.log("error is = ", error)
            }
            /*Notification Create Rider End */


            try {
                let data = await axios.patch(`${process.env.REACT_APP_API_BASE_URL}/order/${id}`, { status: "Preparing" })
                setOrderStatus(data?.data?.data?.staus)

                let finalData = {
                    driverId: selectedItem,
                    orderId: id,
                    type: 'OrderPreparing',
                    userId: data?.data?.data?.userId,
                    message: `Your Order No.${data?.data?.data?.orderId} is Preparing`
                };

                const preparingData = await axios.post(
                    `${process.env.REACT_APP_API_BASE_URL}/notification/create`,
                    finalData
                );


                // Rider Assign
                toast.success(`Order ${data?.data?.data?.orderId} is Preparing`, { position: "bottom-right" });
                socket.emit('orderPreparing', { data: preparingData?.data?.data })
            } catch (error) {
                console.log('error is =>>>>>>>>>> ', err)

            }

            if (driverData) {
                await axios.patch(`${process.env.REACT_APP_API_BASE_URL}/order/${id}`, {
                    driverAssigned: true, driverId: selectedItem
                });
                // toast.success('Driver Assigned Succesfully!', { position: "bottom-right" });
            }

        } catch (error) {
            setAssinDriver(false)
        }
    };

    const [readyData, setReadyData] = useState(false)


    const handleReadyPickUp = async () => {
        try {

            setReadyData(false)
            const orderData = await axios.patch(`${process.env.REACT_APP_API_BASE_URL}/order/${id}`, { status: "Ready to pickup" });
            setOrderStatus(orderData?.data?.data?.status)

            try {
                const orderReady = {
                    driverId: orderData?.data?.data?.driverId,
                    userId: orderData?.data?.data?.userId,
                    orderId: id,
                    type: 'OrderReady',
                    message: `Order is No.${orderData?.data?.data?.orderId} ready to pickup`
                }
                const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/notification/create`, orderReady);
                socket.emit('orderReady', { data: response?.data?.data })
                socket.emit('orderReadyDriver', { data: response?.data?.data })

                toast.success(`Order No. ${orderData?.data?.data?.orderId} Ready to pickup Successfully!`, { position: "bottom-right" });

            } catch (error) {
                setReadyData(false)
                toast.error(err?.response?.data?.message, { position: "bottom-right" });
            }

        } catch (err) {
            setReadyData(false)
            toast.error(err?.response?.data?.message, { position: "bottom-right" });
        }
    }


    socket.on("ordercanceladmin", (data) => {
        setViewData(data?.orderData)
        setDishData(data?.orderData?.items)
        console.log('data?.orderData => ', data?.orderData)
    })

    // console.log("view Data is =>>>>>>>>>>>>>>>>>> ", viewData)
    return (



        <div className='detail-page-section'>
            <p className='info-text'>Order Info</p>

            <div className='Section-new'>

                <div className='Main-box-1'>

                    <div className='order-info-box'>
                        <p className='order-text-1'> Order Id </p>
                        <p className='order-text-2'>{viewData?.orderId}</p>
                    </div>

                    <div className='order-info-box order-info-box-right'>
                        <p className='order-text-1'>Order Price</p>
                        <p className='order-text-2'>{viewData?.totalPrice}</p>
                    </div>

                </div>


                <div className='Main-box-1'>

                    <div className='order-info-box'>
                        <p className='order-text-1'> Order Quantity</p>
                        <p className='order-text-2'>{viewData?.totalQuantity}</p>
                    </div>

                    <div className='order-info-box order-info-box-right'>
                        <p className='order-text-1'>Discount Amount</p>
                        <p className='order-text-2'>{viewData?.discount}</p>
                    </div>

                </div>


                <div className='Main-box-1'>

                    {/* <div className='order-info-box'>
                        <p className='order-text-1'>Discount Code</p>
                        <p className='order-text-2'>NA</p>
                    </div> */}


                    <div className='order-info-box'>
                        <p className='order-text-1'>Status</p>
                        <p className='order-text-2'>{viewData?.status ? viewData?.status : "NA"}</p>
                    </div>

                    <div className='order-info-box'>
                        <p className='order-text-1'>Total amount</p>
                        <p className='order-text-2'>{viewData?.totalPrice ? viewData?.totalPrice + viewData?.discount : "NA"}</p>
                    </div>

                </div>
            </div>




            <p className='info-text'>User Info</p>

            <div className='Section-new'>
                <div className='Main-box-1'>
                    <div className='order-info-box'>
                        <p className='order-text-1'> Name  </p>
                        <p className='order-text-2'>{viewData?.userId?.firstName ? viewData?.userId?.firstName : "NA"} </p>
                    </div>
                    <div className='order-info-box order-info-box-right '>
                        <p className='order-text-1'>Address </p>
                        <p className='order-text-2'>{viewData?.address ? viewData?.address : "NA"}</p>
                    </div>

                </div>


                <div className='Main-box-1'>

                    <div className='order-info-box'>
                        <p className='order-text-1'>  User Email</p>
                        <p className='order-text-2'>{viewData?.userId?.email ? viewData?.userId?.email : "NA"}</p>
                    </div>
                    <div className='order-info-box order-info-box-right'>
                        <p className='order-text-1'>Contact Number</p>
                        <p className='order-text-2'>{viewData?.userId?.mobile ? viewData?.userId?.mobile : "NA"}</p>
                    </div>

                </div>
            </div>





            <p className='info-text'>Product Info</p>

            <div className='section-box-2'>
                <div className='Box-Upper-2'>
                    <p className='TableHeading'>ORDER NAME</p>

                    <div className='Inside-Box-2'>
                        <p className='TableHeading'>QTY</p>
                        <p className='TableHeading'>PRICE</p>
                    </div>
                </div>
                {/* {viewData?.items?.map((x) => (
                    <span>{x?.item?.title}</span>
                    ))} */}


                {
                    viewData?.items?.map((x) => (

                        <div className='Box-Upper-2'>
                            <p>
                                {x?.item?.title}
                            </p>

                            <div className='Inside-Box-2'>
                                <p>{x?.qty}</p>

                                <p>
                                    {x?.item?.price}
                                </p>
                            </div>
                        </div>
                    ))
                }


            </div>

            <div className='Btn-section'>
                <div className='new-box-2'>
                    {
                        orderStatus === "Pending" ? (
                            <Button className='Button-box-1' onClick={AcceptOrder} disabled={acceptOrder || orderStatus == 'Cancelled'}>Accept order</Button>
                        ) : null
                    }


                    <div>
                        {/* {toggleAssignRider && (
                           


                        )} */}
                        {
                            orderStatus == "Accepted" ? (
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">
                                        Assign Driver
                                    </InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        label="Assign Driver"
                                        onChange={SelectDriverHandle}
                                        value={selectedItem}
                                        style={{ width: '150px' }}
                                    >
                                        {ridersList?.map((item) => {
                                            if (item?.status != "Pending" && item?.status != "Rejected" && item?.available != false && item?.occupied != true) {
                                                return (
                                                    <MenuItem value={item?._id} key={item?._id}>
                                                        {item?.firstName}
                                                    </MenuItem>
                                                );
                                            } else {
                                                return null;
                                            }
                                        })}
                                    </Select>
                                </FormControl>

                            ) : null
                        }



                    </div>
                    {
                        orderStatus == "Accepted" && selectedItem != '' ? (
                            <Button className='Button-box-1' onClick={handleAssignDriver} disabled={assignDriver == true}>Assign Driver</Button>
                        ) : null
                    }

                    {
                        orderStatus == "Pending" ? (
                            <Button className="Button-box-1" onClick={handleOrderCancel} disabled={orderCancelButton || orderStatus === "Cancelled"}>
                                Cancel Order</Button>
                        ) : null
                    }


                </div>
                {
                    orderStatus == "Preparing" ? (
                        <Button className='Button-box-1' onClick={handleReadyPickUp} disabled={readyData === true}>Ready to pickup</Button>
                    ) : null
                }

            </div>


            <div className='Section-4'>
                <p className='pickup-text'>Pickup Info</p>
            </div>
            {console.log("assignData=----------------- ", assignData)}

            <div className='section-5'>
                <div className='driver-detail'>
                    <p className='detail-1'>Pickup person</p>
                    <p className='detail-2'>{assignData?.firstName ? assignData?.firstName : "NA"}</p>
                </div>

                <div className='driver-detail'>
                    <p className='detail-1'>phone</p>
                    <p className='detail-2'>{assignData?.mobile ? assignData?.mobile : "NA"}</p>
                </div>


                <div>
                    <img className='driver-img' src=''></img>
                </div>
            </div>

            <div>
                <Button className='Button-box-12' onClick={() => navigate('/orders')}>Back To Orders</Button>

            </div>
        </div>



    );
}
export default ViewTable;







