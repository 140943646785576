import { useState, useEffect } from 'react';
import {
  Box,
  TextField,
  Card,
  CardContent,
  Typography,
  Button,
  CardHeader,
  Container,
  Divider,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { useLocation, useNavigate, useParams } from 'react-router';
import axios from 'axios';
import AWS from 'aws-sdk';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

AWS.config.update({
  accessKeyId: 'AKIARWTYEXZGV5I6UJQJ',
  secretAccessKey: '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',
  region: 'ap-south-1',
});


const EditDish = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const s3 = new AWS.S3();



  const [title, setTitle] = useState('');
  const [price, setPrice] = useState('');
  const [specialPrice, setSpecialPrice] = useState('');
  const [description, setDescription] = useState('');
  const [sku, setSku] = useState('');
  const [categoryId, setCategoryId] = useState('');
  const [imageURL, setImageURL] = useState('');

  const [titleErr, setTitleErr] = useState(false);
  const [priceErr, setPriceErr] = useState(false);
  const [specialPriceErr, setSpecialPriceErr] = useState(false);
  const [descriptionErr, setDescriptionErr] = useState(false);
  const [skuErr, setSkuErr] = useState(false);
  const [categoryIdErr, setCategoryIdErr] = useState(false);
  const [imageErr, setImageErr] = useState(false);
  const [categoryies, setcategories] = useState([]);

  const [submitData, setSubmitData] = useState(false)

  const DishData = async () => {
    try {
      let data = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/dish/${id}`)
      setTitle(data?.data?.title)
      setPrice(data?.data?.price)
      setSpecialPrice(data?.data?.specialPrice)
      setDescription(data?.data?.description)
      setSku(data?.data?.sku)
      setImageURL(data?.data?.images)
      setCategoryId(data?.data?.categoryId);
    } catch (error) {
      toast.error(error?.response?.data?.message, { position: "bottom-right" });
    }
  }

  useEffect(() => {
    DishData();
  }, [])

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/category/getall/?filter={}`
      );
      setcategories(response?.data);
    } catch (err) {
      toast.error(err?.response?.data?.message, { position: "bottom-right" });
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleEdit = async () => {
    try {

      if (title == '') {
        setTitleErr(true);
        return;
      }
      if (price == '') {
        setPriceErr(true);
        return;
      } else {
        const priceRegex = /^[0-9]+$/;
        if (!priceRegex.test(price)) {
          setPriceErr(true);
          return;
        }
      }

      if (specialPrice == '') {
        setSpecialPriceErr(true);
        return;
      } else {
        const specialPriceData = /^[0-9]+$/;
        if (!specialPriceData.test(specialPrice)) {
          setSpecialPriceErr(true);
          return;
        }
      }

      if (description == '') {
        setDescriptionErr(true);
        return;
      }

      if (imageURL == '') {
        setImageErr(true);
        return;
      }

      if (sku == '') {
        setSkuErr(true);
        return;
      }
      if (categoryId == '') {
        setCategoryIdErr(true);
        return;
      }

      setSubmitData(true)
      await axios.patch(
        `${process.env.REACT_APP_API_BASE_URL}/dish/${id}`, {
        title: title, price: price, specialPrice: specialPrice, description: description, sku: sku,
        images: imageURL, categoryId: categoryId.trim()
      }
      );


      toast.success('Dish Updated Successfully!', { position: "bottom-right" });
      navigate('/dishes');

    } catch (error) {
      toast.error(error?.response?.data?.message, { position: "bottom-right" });
    }
  };

  const handleImageChange = async (e) => {

    setImageErr(false)

    if (e.target.files[0]?.name) {
      const params = {
        Bucket: 'suribucket-v2',
        Key: `${Date.now()}.${e?.target?.files[0]?.name}`,
        Body: e?.target?.files[0],
        ACL: 'public-read-write'
      };

      try {
        const { Location } = await s3.upload(params).promise();
        setImageURL(Location);
      } catch (error) {
        toast.error(error, { position: "bottom-right" });
      }
    }
  };

  return (
    <>
      <Helmet>
        <title>Edit Dish</title>
      </Helmet>

      <Container maxWidth="lg" sx={{ mt: 2 }}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={2}
        >
          <Grid item xs={12}>
            <Card>
              <CardHeader title="Edit Dish" />
              <Divider />
              <CardContent>
                <Box
                  component="form"
                  sx={{
                    '& .MuiTextField-root': { m: 1, width: '25ch' }
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <div className='UpperBox'>

                    <div>
                      <TextField
                        id="outlined-required"
                        label="Title"
                        name="title"
                        value={title}
                        onChange={(e) => {
                          setTitle(e.target.value);
                          setTitleErr(false);
                        }}
                      />
                      {titleErr && (
                        <span
                          style={{
                            color: 'red',
                            display: 'flex',
                            paddingTop: '10px'
                          }}
                        >
                          Title is required.
                        </span>
                      )}
                    </div>

                    <div>
                      <TextField
                        id="outlined-required"
                        label="Price"
                        name="price"
                        value={price ? price : ''}
                        onChange={(e) => {
                          setPrice(e.target.value);
                          setPriceErr(false);
                        }}
                      />
                      {priceErr && (
                        <span
                          style={{
                            color: 'red',
                            display: 'flex',
                            paddingTop: '10px'
                          }}
                        >
                          Please Enter Valid Price
                        </span>
                      )}
                    </div>

                    <div>
                      <TextField
                        id="outlined-required"
                        label="Special Price"
                        name="specialprice"
                        value={specialPrice ? specialPrice : ''}
                        onChange={(e) => {
                          setSpecialPrice(e.target.value);
                          setSpecialPriceErr(false);
                        }}
                      />
                      {specialPriceErr && (
                        <span
                          style={{
                            color: 'red',
                            display: 'flex',
                            paddingTop: '10px'
                          }}
                        >
                          Please Enter Valid required.
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="UpperBox">

                    <div>
                      <TextField
                        id="outlined-required"
                        label="Description"
                        name="description"
                        value={description ? description : ''}
                        onChange={(e) => {
                          setDescription(e.target.value);
                          setDescriptionErr(false);
                        }}
                      />
                      {descriptionErr && (
                        <span
                          style={{
                            color: 'red',
                            display: 'flex',
                            paddingTop: '10px'
                          }}
                        >
                          Description is required.
                        </span>
                      )}
                    </div>

                    <div>
                      <TextField
                        id="outlined-required"
                        label="Sku"
                        name="sku"
                        value={sku ? sku : ''}
                        onChange={(e) => {
                          setSku(e.target.value);
                          setSkuErr(false);
                        }}
                      />
                      {skuErr && (
                        <span
                          style={{
                            color: 'red',
                            display: 'flex',
                            paddingTop: '10px'
                          }}
                        >
                          Sku is required.
                        </span>
                      )}
                    </div>

                    <div style={{ margin: 9 }}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Category Id
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Category Id"
                          name="categoryId"
                          value={categoryId ? categoryId : ''}
                          onChange={(e) => {
                            setCategoryId(e.target.value);
                            setCategoryIdErr(false);
                          }}
                          style={{ width: '150px' }}
                        >
                          {categoryies?.map((item) => (
                            <MenuItem value={item?._id} key={item?._id}>
                              {item?.title}
                              {console.log('HUUU ', item?.title)}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      {categoryIdErr && (
                        <span
                          style={{
                            color: 'red',
                            display: 'flex',
                            paddingTop: '10px'
                          }}
                        >
                          Category Id is required.
                        </span>
                      )}
                    </div>
                    </div>

                    <div style={{ margin: 12 }}>
                      <div>
                        {imageURL && <img alt="Image" className='food-image'
                          style={{ borderRadius: '10px' }} src={imageURL} width='26%' height='200px'

                        />
                        }
                        <input
                          className='choose-file'
                          style={{ width: '20%', padding: '10px', height: '40px', display: 'flex', borderRadius: '6px', borderWidth: '1px', borderStyle: 'solid', borderColor: '#c4c4c4' }}
                          type="file"
                          accept="image/*"
                          onChange={handleImageChange}
                        />
                      </div>
                      {imageErr && (
                        <span
                          style={{
                            color: 'red',
                            display: 'flex',
                            paddingTop: '10px'
                          }}
                        >
                          Image is Required
                        </span>
                      )}
                    </div>
                  

                  <div style={{ display: 'flex', marginLeft: '10px', gap: '20px', fontSize: '30px' }}>
                    <Button style={{ background: '#5569ff ', color: '#fff' }} className='SubmitBtn10'
                      onClick={handleEdit} disabled={submitData === true}>
                      Submit
                    </Button>
                    <Button style={{ background: '#5569ff ', color: '#fff' }} className='SubmitBtn10'
                      onClick={() => navigate('/dishes')}>
                      Back to Dishes
                    </Button>
                  </div>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container >
    </>
  );
};

export default EditDish;
