import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { RouteObject } from 'react-router';
import SidebarLayout from 'src/layouts/SidebarLayout';
import BaseLayout from 'src/layouts/BaseLayout';
import SuspenseLoader from 'src/components/SuspenseLoader';
import DishList from 'src/content/applications/Dish/DishListing/index';
import Orders from './content/applications/Orders/Dishlist';
import OrdersTable from './content/applications/Orders/Dishlist/OrderListTable';
import AddRider from './content/applications/Riders/AddRiders';
import RiderList from './content/applications/Riders/Riderlist';
import CategoryList from './content/applications/Category/CategoryList';
import AddCategory from './content/applications/Category/AddCategory';
import EditCategory from './content/applications/Category/EditCategory/EditCategory';
import EditDish from './content/applications/Dish/EditDish/EditDish';
import EditRider from './content/applications/Riders/EditRider/EditRider';
import EditOrder from './content/applications/Orders/Dishlist/EditOrders/EditOrder';
import EditUser from './content/applications/Users/EditUser/EditUser';
import AddWaiter from './content/applications/Users/profile/AddWaiter/AddWaiter';
import Login from './components/Login';
import { PublicRoute } from './Utils/PublicRoute';
import ProtectedRoute from './Utils/ProtectedRoute';
import ViewTable from './content/applications/Orders/Dishlist/ViewTable';
import EditWaiter from './content/applications/Users/profile/EditWaiter';
import WaiterList from './content/applications/Users/profile/WaiterList/WaiterList';
import ViewRider from './content/applications/Riders/ViewRiders';
import ViewDishTable from './content/applications/Dish/ViewDish';
import AddDiscount from './content/applications/Discount/addDiscount';
import ListDiscount from './content/applications/Discount/listDiscount';
import ApprovedDriver from './content/applications/Riders/Riderlist/ApprovedDriver';
import PendingDriver from './content/applications/Riders/Riderlist/PendingDriver';
import RejectedDriver from './content/applications/Riders/Riderlist/RejectedDriver';

const Loader = (Component) => (props) =>
(
  <Suspense fallback={<SuspenseLoader />}>
    <Component {...props} />
  </Suspense>
);

// Pages
const Overview = Loader(lazy(() => import('src/content/overview')));

// Dashboards
const Crypto = Loader(lazy(() => import('src/content/dashboards/Crypto')));

// Applications
const Messenger = Loader(
  lazy(() => import('src/content/applications/Messenger'))
);
const Transactions = Loader(
  lazy(() => import('src/content/applications/Transactions'))
);
const Waiters = Loader(
  lazy(() => import('src/content/applications/Users/profile'))
);
// const AddWaiter = Loader(
//   lazy(() => import('src/content/applications/Users/profile'))
// );


const UsersList = Loader(
  lazy(() => import('src/content/applications/Users/user-listing/index'))
);
const AddUser = Loader(
  lazy(() => import('src/content/applications/Users/add-user/index'))
);
const AddDish = Loader(
  lazy(() => import('src/content/applications/Dish/AddDish/index'))
);
const ListDish = Loader(
  lazy(() => import('src/content/applications/Dish/DishListing/index'))
);


const routes: RouteObject[] = [
  {
    path: '',
    element: <BaseLayout />,
    children: [
      {
        path: '/',
        element: (
          <PublicRoute>
            {' '}
            <Login />
          </PublicRoute>
        )
      },
      {
        path: '/login',
        element: (
          <PublicRoute>
            {' '}
            <Login />
          </PublicRoute>
        )
      },
      {
        path: 'overview',
        element: <Navigate to="/" replace />
      },
      {
        path: 'status',
        children: [
          {
            path: '',
            element: <Navigate to="404" replace />
          },

        ]
      },

    ]
  },

  {
    path: 'users',
    element: <SidebarLayout />,

    children: [
      {
        path: '',
        element: (
          <ProtectedRoute>
            {' '}
            <UsersList />{' '}
          </ProtectedRoute>
        )
      },
      {
        path: 'list',
        element: (
          <ProtectedRoute>
            {' '}
            <UsersList />{' '}
          </ProtectedRoute>
        )
      },
      {
        path: 'add-user',
        element: (
          <ProtectedRoute>
            {' '}
            <AddUser />
          </ProtectedRoute>
        )
      },
      {
        path: 'edit-user/:id',
        element: (
          <ProtectedRoute>
            {' '}
            <EditUser />{' '}
          </ProtectedRoute>
        )
      }
    ]
  },
  {
    path: 'dishes',
    element: <SidebarLayout />,

    children: [
      {
        path: '',
        element: (
          <ProtectedRoute>
            {' '}
            <DishList />{' '}
          </ProtectedRoute>
        )
      },
      {
        path: 'list',
        element: (
          <ProtectedRoute>
            {' '}
            <DishList />
          </ProtectedRoute>
        )
      },
      {
        path: 'add-dish',
        element: (
          <ProtectedRoute>
            {' '}
            <AddDish />
          </ProtectedRoute>
        )
      },
      {
        path: 'edit-dish/:id',
        element: (
          <ProtectedRoute>
            <EditDish />{' '}
          </ProtectedRoute>
        )
      },
      {
        path: 'view/:id',
        element: (
          <ProtectedRoute>
            <ViewDishTable />{' '}
          </ProtectedRoute>
        )
      }
    ]
  },

  {
    path: 'Orders',
    element: <SidebarLayout />,

    children: [
      {
        path: '',
        element: (
          <ProtectedRoute>
            <Orders />{' '}
          </ProtectedRoute>
        )
      },
      {
        path: 'list',
        element: (
          <ProtectedRoute>
            {' '}
            <OrdersTable />{' '}
          </ProtectedRoute>
        )
      },
      {
        path: 'edit-order/:id',
        element: (
          <ProtectedRoute>
            <EditOrder />{' '}
          </ProtectedRoute>
        )
      },
      {
        path: 'view/:id',
        element: (
          <ProtectedRoute>
            <ViewTable />{' '}
          </ProtectedRoute>
        )
      },
      // {
      //   path: 'view/notification/:id',
      //   element: (
      //     <ProtectedRoute>
      //       <ViewNotification />{' '}
      //     </ProtectedRoute>
      //   )
      // }
    ]
  },

  {
    path: 'Riders',
    element: <SidebarLayout />,

    children: [
      {
        path: '',
        element: (
          <ProtectedRoute>
            {' '}
            <RiderList />{' '}
          </ProtectedRoute>
        )
      },
      {
        path: 'list',
        element: (
          <ProtectedRoute>
            {' '}
            <RiderList />
          </ProtectedRoute>
        )
      },
      {
        path: 'add-rider',
        element: (
          <ProtectedRoute>
            {' '}
            <AddRider />
          </ProtectedRoute>
        )
      },
      {
        path: 'edit-rider/:id',
        element: (
          <ProtectedRoute>
            {' '}
            <EditRider />
          </ProtectedRoute>
        )
      },
      {
        path: 'approvedDriver',
        element: (
          <ProtectedRoute>
            {' '}
            <ApprovedDriver />
          </ProtectedRoute>
        )
      },
      {
        path: 'pendingDriver',
        element: (
          <ProtectedRoute>
            {' '}
            <PendingDriver />
          </ProtectedRoute>
        )
      },
      {
        path: 'rejectedDriver',
        element: (
          <ProtectedRoute>
            {' '}
            <RejectedDriver />
          </ProtectedRoute>
        )
      },
      {
        path: 'view/:id',
        element: (
          <ProtectedRoute>
            <ViewRider />{' '}
          </ProtectedRoute>
        )
      }
    ]
  },

  {
    path: 'categories',
    element: <SidebarLayout />,

    children: [
      {
        path: '',
        element: (
          <ProtectedRoute>
            {' '}
            <CategoryList />
          </ProtectedRoute>
        )
      },
      {
        path: 'list',
        element: (
          <ProtectedRoute>
            {' '}
            <CategoryList />{' '}
          </ProtectedRoute>
        )
      },
      {
        path: 'add-category',
        element: (
          <ProtectedRoute>
            {' '}
            <AddCategory />
          </ProtectedRoute>
        )
      },
      {
        path: 'edit-category/:id',
        element: (
          <ProtectedRoute>
            {' '}
            <EditCategory />
          </ProtectedRoute>
        )
      }
    ]
  },

  {
    path: 'dashboards',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="crypto" replace />
      },
      {
        path: 'crypto',
        element: (
          <ProtectedRoute>
            {' '}
            <Crypto />{' '}
          </ProtectedRoute>
        )
      },
      {
        path: 'messenger',
        element: (
          <ProtectedRoute>
            {' '}
            <Messenger />{' '}
          </ProtectedRoute>
        )
      }
    ]
  },
  {
    path: 'waiters',
    element: <SidebarLayout />,

    children: [
      {
        path: '',
        element: (
          <ProtectedRoute>
            {' '}
            <WaiterList />
          </ProtectedRoute>
        )
      },
      {
        path: 'list',
        element: (
          <ProtectedRoute>
            {' '}
            <WaiterList />{' '}
          </ProtectedRoute>
        )
      },
      {
        path: 'add-waiter',
        element: (
          <ProtectedRoute>
            {' '}
            <AddWaiter />
          </ProtectedRoute>
        )
      },
      {
        path: 'edit-waiter/:id',
        element: (
          <ProtectedRoute>
            {' '}
            <EditWaiter />
          </ProtectedRoute>
        )
      }
    ]
  },
  {
    path: '/components',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="buttons" replace />
      },

    ]
  },
  {
    path: 'discounts',
    element: <SidebarLayout />,

    children: [
      {
        path: '',
        element: (
          <ProtectedRoute>
            {' '}
            <ListDiscount />
          </ProtectedRoute>
        )
      },
      {
        path: 'list',
        element: (
          <ProtectedRoute>
            {' '}
            <ListDiscount />{' '}
          </ProtectedRoute>
        )
      },
      {
        path: 'add-discount',
        element: (
          <ProtectedRoute>
            {' '}
            <AddDiscount />
          </ProtectedRoute>
        )
      },

    ]
  },
];

export default routes;
