import { FC, ChangeEvent, useState, useEffect } from 'react';
import {
  Tooltip, Divider, Box, FormControl, InputLabel, Card, IconButton, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, TableContainer, Select, MenuItem, Typography, useTheme,
  CardHeader
} from '@mui/material';

import { CryptoOrder, CryptoOrderStatus } from 'src/models/crypto_order';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import Button from '@mui/material/Button';
import VisibilityIcon from '@mui/icons-material/Visibility';
import './dishlisting.css';


interface CategoryListTableProps {
  className?: string;
}

interface Filters {
  status?: CryptoOrderStatus;
}

const applyFilters = (cryptoOrders: any[], filters: Filters): CryptoOrder[] => {
  return cryptoOrders.filter((cryptoOrder) => {
    let matches = true;
    return matches;
  });
};

const applyPagination = (
  cryptoOrders: CryptoOrder[],
  page: number,
  limit: number
): CryptoOrder[] => {
  if (cryptoOrders?.length <= limit) {
    return cryptoOrders;
  } else {
    return cryptoOrders.slice(page * limit, page * limit + limit);
  }
};

const DishTable: FC<CategoryListTableProps> = ({ }) => {
  const [cryptoOrders, setcryptoOrdersPage] = useState([]);
  const [orders, setOrders] = useState([]);
  const [selectedCryptoOrders, setSelectedCryptoOrders] = useState<string[]>(
    []
  );
  const selectedBulkActions = selectedCryptoOrders.length > 0;
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(5);
  const [filters, setFilters] = useState<Filters>({
    status: null
  });

  const handlePageChange = (event: any, newPage: number): void => {
    setPage(newPage);
  };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value));
  };

  const filteredCryptoOrders = applyFilters(cryptoOrders, filters);
  let paginatedCryptoOrders = applyPagination(
    filteredCryptoOrders,
    page,
    limit
  );
  const selectedSomeCryptoOrders =
    selectedCryptoOrders.length > 0 &&
    selectedCryptoOrders.length < cryptoOrders.length;
  const selectedAllCryptoOrders =
    selectedCryptoOrders.length === cryptoOrders.length;
  const theme = useTheme();
  const navigate = useNavigate();

  const fetchDishes = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/dish/getall/?filter={}`
      );
      setcryptoOrdersPage(response?.data);
      setOrders(response?.data)
    } catch (err) {
    }
  };

  const [statusFilter, setStatusFilter] = useState('all');
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    if (statusFilter === 'all') {
      setcryptoOrdersPage(orders)
      // setcryptoOrdersPage(cryptoOrders)
    } else {
      let data = orders.filter((item) => item.status === statusFilter);
      setcryptoOrdersPage(data)
    }
  }, [statusFilter]);
  // paginatedCryptoOrders = applyPagination(filteredCryptoOrders, page, limit);

  console.log('cryptoOrders', cryptoOrders)
  const updateFilter = (e) => {
    setStatusFilter(e.target.value);
  };

  useEffect(() => {
    fetchDishes();
  }, []);


  const handleDelete = async (id: any) => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_BASE_URL}/dish/${id}`);
      fetchDishes();
      toast.success('Dish Deleted Successfully!', { position: "bottom-right" });
    } catch (error) {
      toast.error(error?.response?.data?.message, { position: "bottom-right" });
    }
  };

  const [availableData, setAvailableData] = useState(false)

  const handleAvailableChange = async (id: any, status: any) => {
    try {
      const newStatus = status === 'Active' ? 'Inactive' : 'Active';
      const data = { status: newStatus };
      setAvailableData(true)
      await axios.patch(
        `${process.env.REACT_APP_API_BASE_URL}/dish/${id}`,
        data
      );

      toast.success(`Dish is ${newStatus}`, { position: "bottom-right" });

      setcryptoOrdersPage((prevCryptoOrders) => {
        const updatedCryptoOrders = prevCryptoOrders.map((order) => {
          if (order._id === id) {
            return { ...order, status: newStatus };
          }
          return order;
        });
        return updatedCryptoOrders;
      });
    } catch (err) {
      setAvailableData(false)
      toast.error(err?.response?.data?.message, { position: "bottom-right" });

    }
  };

  return (
    <>
      {/* <Container maxWidth="md" sx={{ mt: 20 }}>
        <TextField
          type="search"
          id="search"
          label="Search"
          sx={{ width: 600 }}
        />
      </Container> */}

      <Card sx={{ m: 2 }}>
        <CardHeader
          action={
            <Box width={150}>
              <FormControl fullWidth variant="outlined">
                <InputLabel className='StatusLabel'>Status</InputLabel>

                <Select
                  variant="filled"
                  className="select-item"
                  value={statusFilter}
                  name="filter"
                  onChange={updateFilter}
                  sx={{
                    border: '0px solid red',
                    outline: 'none',
                    boxShadow: 'none'
                  }}
                >
                  <MenuItem value={'all'}>All</MenuItem>
                  <MenuItem value={'Active'}>Active</MenuItem>
                  <MenuItem value={'Inactive'}>Inactive</MenuItem>
                </Select>
              </FormControl>
            </Box>
          }
          title="All Dishes"
        />

        <Divider />
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align='center'>Title</TableCell>
                <TableCell align='center'>Price</TableCell>
                <TableCell align='center'>Special Price</TableCell>
                <TableCell align="right">Status</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedCryptoOrders?.map((cryptoOrder: any) => {
                const isCryptoOrderSelected = selectedCryptoOrders.includes(
                  cryptoOrder.id
                );
                return (
                  <TableRow
                    hover
                    key={cryptoOrder.id}
                    selected={isCryptoOrderSelected}
                  >
                    <TableCell align='center'>
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                        noWrap
                      >
                        {cryptoOrder?.title}
                      </Typography>
                    </TableCell>
                    <TableCell align='center'>
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                        noWrap
                      >
                        {cryptoOrder?.price}
                      </Typography>
                    </TableCell>

                    <TableCell align='center'>
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                        noWrap
                      >
                        {cryptoOrder?.specialPrice}
                      </Typography>
                    </TableCell>

                    <TableCell align="right">{cryptoOrder?.status}</TableCell>
                    <TableCell align="right">
                      <Button className='avail-btn'
                        // disabled={availableData === true}
                        variant="contained"
                        color={
                          cryptoOrder?.status === 'Active' ? 'error' : 'success'
                        }
                        onClick={() =>
                          handleAvailableChange(
                            cryptoOrder?._id,
                            cryptoOrder?.status
                          )
                        }
                      >
                        {cryptoOrder?.status === 'Active'
                          ? 'Not Available'
                          : 'Available'}
                      </Button>

                      <Tooltip title="Edit Order" arrow>
                        <IconButton
                          sx={{
                            '&:hover': {
                              background: theme.colors.primary.lighter
                            },
                            color: theme.palette.primary.main
                          }}
                          color="inherit"
                          size="small"

                        >
                          <VisibilityIcon fontSize="small"
                            onClick={() => {
                              const encodedDish = encodeURIComponent(JSON.stringify(cryptoOrder));
                              navigate(`/dishes/view/${cryptoOrder?._id}?dish=${encodedDish}`)
                            }


                            } />
                        </IconButton>
                      </Tooltip>


                      <Tooltip title="Edit Dishes" arrow>
                        <IconButton
                          sx={{
                            '&:hover': {
                              background: theme.colors.primary.lighter
                            },
                            color: theme.palette.primary.main
                          }}
                          color="inherit"
                          size="small"
                        >
                          <EditTwoToneIcon
                            fontSize="small"
                            onClick={() =>
                              navigate(`/dishes/edit-dish/${cryptoOrder?._id}`)}
                          />
                        </IconButton>
                      </Tooltip>

                      {/* Delete Icon */}
                      <Tooltip title="Delete Dishes" arrow>
                        <IconButton
                          sx={{
                            '&:hover': {
                              background: theme.colors.error.lighter
                            },
                            color: theme.palette.error.main
                          }}
                          color="inherit"
                          size="small"
                        >
                          <DeleteTwoToneIcon
                            fontSize="small"
                            onClick={() => handleDelete(cryptoOrder?._id)}
                          />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <Box p={2}>
          <TablePagination
            component="div"
            count={filteredCryptoOrders.length}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleLimitChange}
            page={page}
            rowsPerPage={limit}
            rowsPerPageOptions={[5, 10, 25, 30]}
          />
        </Box>
      </Card>
    </>
  );
};

export default DishTable;
