import { useState, useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import axios from 'axios';
import { App, socket } from 'src/App';

import { useNavigate } from 'react-router';
import {
  FormControl,
  IconButton,
  InputAdornment,
  OutlinedInput
} from '@mui/material';
const defaultTheme = createTheme();

interface FormData {
  email: string;
  password: string;
}
const initialValue: FormData = {
  email: '',
  password: ''
};

const SignIn = () => {
  const navigate = useNavigate();
  const [loggedIn, setloggedIn] = useState(false);
  const [formData, setFormData] = useState<FormData>(initialValue);
  const [errors, setErrors] = useState<Partial<FormData>>({});
  const [showPassword, setShowPassword] = useState(false);

  const ValidateForm = () => {
    const errors: Partial<FormData> = {};
    if (!formData?.email.trim()) {
      errors.email = 'Email is required';
    }
    if (!formData?.password.trim()) {
      errors.password = 'Password is required';
    }
    return errors;
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    setErrors((preError) => ({
      ...preError,
      [name]: undefined
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const err = ValidateForm();
    if (Object.keys(err).length === 0) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/admin/login`,
          formData
        );
        // console.log("======================", response?.data?.data?._id)
        // socket.emit('join', response?.data?.data?._id);

        // localStorage.setItem("loginUser", JSON.stringify(response?.data?.data))

        if (response?.data) {
          setloggedIn(true);
        }
        navigate('/dashboards/crypto');
      } catch (error) {
        console.log(error);
      }
    } else {
      console.log('err', err);
      setErrors(err);
    }
  };

  useEffect(() => {
    localStorage.setItem('IsloggedIn', JSON.stringify(loggedIn));
  }, [loggedIn]);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  return (
    // <ThemeProvider>
    <Container component="main" maxWidth="xs">
      {/* <CssBaseline /> */}
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <Box
          component="form"
          onSubmit={handleSubmit}
          noValidate
          sx={{ mt: 1 }}
        >
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            value={formData?.email}
            onChange={handleChange}
            autoComplete="email"
            autoFocus
          />
          {errors?.email && (
            <span style={{ color: 'red', paddingTop: '15px', marginBottom: '10px' }}>
              {errors.email}
            </span>
          )}

          <FormControl sx={{ width: '100%' }} variant="outlined">
            <OutlinedInput
              id="outlined-adornment-password"
              type={showPassword ? 'text' : 'password'}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    className="eye-icon"
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword((show) => !show)}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                    color="primary"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
              placeholder="Password"
              name="password"
              value={formData?.password}
              onChange={handleChange}
            />
          </FormControl>

          {/* <div style={{display:'flex'}}>
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type={showPassword ? 'text' : 'password'}
                id="password"
                onChange={handleChange}
                value={formData?.password}
                autoComplete="current-password"
              />
              <IconButton
                className="eye-icon"
                aria-label="toggle password visibility"
                onClick={() => setShowPassword((show) => !show)}
                onMouseDown={handleMouseDownPassword}
                edge="end"
                color="primary"
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </div> */}

          {errors?.password && (
            <span style={{ color: 'red', paddingTop: '10px' }}>
              {errors.password}
            </span>
          )}

          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Sign In
          </Button>
        </Box>
      </Box>
    </Container>
    // </ThemeProvider>
  );
};

export default SignIn;
