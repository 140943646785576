import { Box, Button, Radio, RadioGroup, FormLabel, FormControl, FormControlLabel, TextField } from "@mui/material"
import { useEffect, useState } from "react"
import { Helmet } from "react-helmet-async";
import axios from 'axios';
import AWS from 'aws-sdk';


import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface FormData {
  name: string;
  email: string;
  mobile: string;
}

const initialFormData: FormData = {
  name: '',
  email: '',
  mobile: '',
};


//storing image in bucket
AWS.config.update({
  accessKeyId: 'AKIARWTYEXZGV5I6UJQJ',
  secretAccessKey: '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',
  region: 'ap-south-1',
  // signatureVersion: 'v4'
});



const AddWaiter = () => {

  const s3 = new AWS.S3();
  const [addRiderData, setAddRiderData] = useState(initialFormData);
  const [errors, setErrors] = useState<Partial<FormData>>({});
  const [imageErr, setImageErr] = useState(false);
  const [image, setImage] = useState(null)

  const [errorCatch, setErrorCatch] = useState('')
  const validateForm = () => {
    const errors: Partial<FormData> = {};

    if (!addRiderData?.name.trim()) {
      errors.name = 'Name is required';
    }


    if (!addRiderData.email) {
      errors.email = 'Email is required';
    } else {
      const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
      if (!emailRegex.test(addRiderData.email)) {
        errors.email = 'Invalid email address';
      }
    }

    if (!addRiderData?.mobile.trim()) {
      errors.mobile = 'Mobile No. is required';
    } else if (!/^\d{10}$/.test(addRiderData.mobile)) {
      errors.mobile = 'Please Provide Valid Phone Number';
    }



    return errors;
  };




  const handleInputChange = (event: any) => {
    const { name, value } = event.target;
    setAddRiderData({ ...addRiderData, [name]: value });
    setErrors((prevErrors) => ({ ...prevErrors, [name]: undefined }));
  };


  const handleRiderSubmit = async (e) => {
    try {
      e.preventDefault();
      const errors = validateForm();
      if (Object.keys(errors).length === 0) {
        const response = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/waiter/signup`, {
          name: addRiderData?.name,
          mobile: addRiderData?.mobile,
          email: addRiderData?.email,

        }
        );
        toast.success('Waiter Added Successfully!', { position: "bottom-right" });

        setTimeout(() => {
          setAddRiderData(initialFormData);
        }, 1000)
      } else {
        setErrors(errors);
        return;
      }
    } catch (err) {

      toast.error(err?.response?.data?.message, {
        position: "bottom-right",
      });
    }
  }
  return (
    <>
      <Helmet>
        <title>Add Waiter</title>
      </Helmet>

      <form
        onSubmit={handleRiderSubmit}
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          gap: '10',
          background: '#fff',
          padding: 15,
          margin: 30,
          borderRadius: 10
        }}
      >
        <div style={{ margin: 4, width: '100%' }}>
          <p
            style={{
              margin: 4,
              width: '100%',
              color: '#000',
              fontWeight: 600,
              marginBottom: '30px'
            }}
          >
            Add Waiter
          </p>
        </div>
        <div style={{ margin: 9 }}>
          <TextField
            id="outlined-required"
            label="Name"
            name="name"
            value={addRiderData?.name}
            onChange={handleInputChange}
          />
          {errors?.name && (
            <span style={{ color: 'red', display: 'flex', paddingTop: '10px' }}>
              {errors?.name}
            </span>
          )}
        </div>


        <div style={{ margin: 9 }}>
          <TextField
            id="outlined-required"
            label="Email"
            name="email"
            value={addRiderData?.email}
            onChange={handleInputChange}
          />
          {errors?.email && (
            <span style={{ color: 'red', display: 'flex', paddingTop: '10px' }}>
              {errors?.email}
            </span>
          )}
        </div>

        <div style={{ margin: 9 }}>
          <TextField
            id="outlined-required"
            label="Mobile"
            name="mobile"
            value={addRiderData?.mobile}
            onChange={handleInputChange}
          />
          {errors?.mobile && (
            <span style={{ color: 'red', display: 'flex', paddingTop: '10px' }}>
              {errors?.mobile}
            </span>
          )}

          {errorCatch && (
            <span style={{ color: 'red', display: 'flex', paddingTop: '10px' }}>
              {errorCatch}
            </span>
          )}
        </div>



        <div style={{ margin: 9, width: '100%' }}>
          <Button variant="contained" type="submit">
            Submit
          </Button>
        </div>
      </form>


    </>
  )
}
export default AddWaiter;