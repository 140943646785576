import { useLocation } from 'react-router';
import moment from "moment";
import { Button } from '@mui/material';
import { useNavigate } from 'react-router';
import './ViewRiders.css';

const ViewRider = () => {
    const navigate = useNavigate();

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const encodedViewOrder = queryParams.get('rider');
    let viewOrder;

    try {
        viewOrder = JSON.parse(encodedViewOrder);
    } catch (error) {
        console.log('Error parsing JSON:', error);
    }
    console.log("order", viewOrder)

    return (
        <div className='detail-page-section'>
            <p className='info-text'>Rider Info</p>

            <div className='Section-new'>

                <div className='Main-box-1'>

                    <div className='order-info-box'>
                        <p className='order-text-1'> FirstName</p>
                        <p className='order-text-2'>{viewOrder?.firstName}</p>
                    </div>

                    <div className='order-info-box order-info-box-right'>
                        <p className='order-text-1'>LastName</p>
                        <p className='order-text-2'> {viewOrder?.lastName}</p>
                    </div>

                </div>


                <div className='Main-box-1'>

                    <div className='order-info-box'>
                        <p className='order-text-1'> Email</p>
                        <p className='order-text-2'>{viewOrder?.email}</p>
                    </div>

                    <div className='order-info-box order-info-box-right'>
                        <p className='order-text-1'> Mobile </p>
                        <p className='order-text-2'>{viewOrder?.mobile}</p>
                    </div>

                </div>


                <div className='Main-box-1'>

                    <div className='order-info-box'>
                        <p className='order-text-1'> Age </p>
                        <p className='order-text-2'> {viewOrder?.age}</p>
                    </div>


                    <div className='order-info-box order-info-box-right'>
                        <p className='order-text-1'>Gender</p>
                        <p className='order-text-2'>{viewOrder?.gender}</p>
                    </div>

                </div>



                <div className='Main-box-1'>

                    <div className='order-info-box'>
                        <p className='order-text-1'>  Date</p>
                        <p className='order-text-2'> {moment(viewOrder?.createdAt).format('YYYY-MM-DD')}</p>
                    </div>


                    <div className='order-info-box order-info-box-right'>
                        <p className='order-text-1'>Time</p>
                        <p className='order-text-2'>{moment(viewOrder?.createdAt).format('HH:mm:ss')}</p>
                    </div>

                </div>
            </div>

            <div>
                <Button className='back-btn' onClick={() => navigate('/riders')}>
                    Back to Riders
                </Button>
            </div>
        </div>
    );
}
export default ViewRider;





