import {
  Box, Button, Card, CardContent, CardHeader, Container, Divider, Grid, TextField
} from '@mui/material';
import axios from 'axios';
import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import AWS from 'aws-sdk';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useLocation, useNavigate, useParams } from 'react-router-dom';


//storing image in bucket
AWS.config.update({
  accessKeyId: 'AKIARWTYEXZGV5I6UJQJ',
  secretAccessKey: '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',
  region: 'ap-south-1'
  // signatureVersion: 'v4'
});

const AddCategory = () => {
  const s3 = new AWS.S3();
  const [title, setTitle] = useState('');
  const [image, setImage] = useState(null);
  const [titleErr, setTitleErr] = useState(false);
  const [imageErr, setImageErr] = useState(false);
  const [file, setFile] = useState();
  const [img, setImg] = useState('');
  const handleChange = (e) => {
    setTitleErr(false);
    setTitle(e.target.value);
  };

  const navigate = useNavigate();

  const handleSubmit = async () => {
    try {
      if (title == '' && (image == '' || image == null)) {
        console.log('title = ', title);
        setTitleErr(true);
        setImageErr(true);
        return;
      }

      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/category/create`,
        {
          title: title,
          bannerImage: img
        }
      );


      setTitle('');
      setImage('');
      toast.success('Category Added Successfully!', { position: "bottom-right" });

      setTimeout(() => {
        navigate('/categories');
      })
    } catch (err) {
      toast.error(err?.response?.data?.message, {
        position: "bottom-right",
      });
    }
  };

  async function handleImageFile(e) {
    setImageErr(false);
    setImage(e.target.files[0]?.name);

    if (e.target.files[0]?.name) {
      const params = {
        Bucket: 'suribucket-v2',
        Key: `${Date.now()}.${e?.target?.files[0]?.name}`,
        Body: e?.target?.files[0],
        ACL: 'public-read-write'
      };
      console.log('===params===========', params);

      try {
        const { Location } = await s3.upload(params).promise();
        console.log('Location', Location);

        setImg(Location);
      } catch (error) {
        toast.error(error, {
          position: "bottom-right",
        });
      }
    }
  }
  console.log('img', img);

  return (
    <>
      <Helmet>
        <title>Add Category</title>
      </Helmet>

      <Container maxWidth="lg" sx={{ mt: 2 }}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={2}
        >
          <Grid item xs={12}>
            <Card>
              <CardHeader title="Add Category" />
              <Divider />
              <CardContent>
                <Box
                  component="form"
                  sx={{
                    '& .MuiTextField-root': { m: 1, width: '25ch' }
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <div>
                    <TextField
                      id="outlined-required"
                      label="Title"
                      name="title"
                      value={title}
                      onChange={handleChange}
                    />
                    {titleErr && (
                      <div style={{ color: 'red', marginLeft: '16px' }}>
                        Title is requierd
                      </div>
                    )}
                  </div>

                  <div style={{ margin: 12 }}>
                    <input type="file" onChange={handleImageFile} />
                    <span> {image}</span>
                    {imageErr && (
                      <div style={{ color: 'red', paddingTop: '10px' }}>
                        Image is required
                      </div>
                    )}
                  </div>

                  <div>
                    <Button onClick={handleSubmit}>Submit</Button>
                  </div>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};
export default AddCategory;
