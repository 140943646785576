import { Helmet } from "react-helmet-async";
import { Container, Grid } from "@mui/material";
import RiderListTable from "./RiderListing";

function RiderList() {
    return (
      <>
        <Helmet>
          <title>Riders</title>
        </Helmet>

        <Container maxWidth="lg">
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="stretch"
            spacing={3}
          >
            <Grid item xs={12}>
              <RiderListTable />
            </Grid>
          </Grid>
        </Container>
      </>
    );
  }
  
  export default RiderList;