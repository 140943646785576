import {
  alpha, Badge, Box, Button, Divider, IconButton, List, ListItem, Popover, TextField, Tooltip, Typography, useTheme
} from '@mui/material';
import { useRef, useState, useEffect } from 'react';
import NotificationsActiveTwoToneIcon from '@mui/icons-material/NotificationsActiveTwoTone';
import { styled } from '@mui/material/styles';
import { formatDistance, subDays } from 'date-fns';
import VisibilityIcon from '@mui/icons-material/Visibility';

import axios from 'axios';
import Modal from '@mui/material/Modal';
import { useNavigate } from 'react-router';
import './Notifications.css';


import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { socket } from 'src/App';



const NotificationsBadge = styled(Badge)(
  ({ theme }) => ` 
    .MuiBadge-badge {
        background-color: ${alpha(theme.palette.error.main, 0.1)};
        color: ${theme.palette.error.main};
        min-width: 16px; 
        height: 16px;
        padding: 0;

        &::after {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            box-shadow: 0 0 0 1px ${alpha(theme.palette.error.main, 0.3)};
            content: "";
        }
    }
`
);

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const HeaderNotifications = () => {



  const [data, setData] = useState({})
  const [count, setCount] = useState('')

  let [notficationdata, setnotficationdata] = useState([]);


  const theme = useTheme();


  const ref = useRef<any>(null);
  const navigate = useNavigate();
  const [openNoti, setOpenNoti] = useState(false);
  const [openNoti1, setOpenNoti1] = useState(false);

  const handleOpenNoti = () => setOpenNoti(true);
  const handleCloseNoti = () => setOpenNoti(false);

  const handleOpenNoti1 = () => setOpenNoti1(true);
  const handleCloseNoti1 = () => setOpenNoti1(false);

  const [paymentAmount, setpaymentAmount] = useState(null);
  const [buttonDisabled, setButtonDisabled] = useState(false);



  socket.on('newOrder', (data) => {
    setnotficationdata(data?.data);
    setCount(data?.count)
  })


  socket.on('orderpickupadmin', (data) => {
    setnotficationdata(data?.data);
    setCount(data?.count)
  })


  socket.on('driverNoti', (data) => {
    setnotficationdata(data?.data);
    setCount(data?.count)
  })


  socket.on('deliverNoti', (data) => {
    setnotficationdata(data?.data);
    setCount(data?.count)
  })

  socket.on("ordercanceladmin", (data) => {
    console.log("data is = ", data)
    setnotficationdata(data?.data);
    console.log("hurrrrrrrrrrrr ", data?.data)
    setCount(data?.count)
    console.log("9)()()(((((((((((((((((((((((", data?.count)
  })


  const fetchNotifications = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/notification/getadminNoti`);

      setnotficationdata(response?.data);
      try {
        let data = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/notification/all/count`)
        setCount(data?.data);
      } catch (error) {
        console.log('error = ', error)
      }
    } catch (err) {
      console.log('Error:', err);
    }
  };



  const handlePayment = async () => {
    if (paymentAmount == null) {
      alert("This Field is required")
      return;
    }

    setButtonDisabled(true);


    let data = JSON.parse(localStorage.getItem('paymentData'))

    try {
      let Data_1 = {
        driverId: data?.driverId, orderId: data?.orderId, paymentStatus: "pending", paymentAmount: paymentAmount
      }
      await axios.post(`${process.env.REACT_APP_API_BASE_URL}/driverPayment/create`, Data_1)
        .then(async (response) => {
          let d = await axios.patch(`${process.env.REACT_APP_API_BASE_URL}/notification/${data?._id}`, { read: true }).then((res) => {


            localStorage.removeItem('data');
            handleCloseNoti();
            handleClose();
            fetchNotifications()
            navigate('/Orders')
            toast.success('Payment Success!', { position: "bottom-right" });
          })

        }).catch((error) => {
          toast.error(error?.response?.data?.message, {
            position: "bottom-right",
          });
        })
    }
    catch (err) {
      toast.error(err?.response?.data?.message, {
        position: "bottom-right",
      });
    }




  }

  // const [Accept, setAccept] = useEffect(false)
  const handleAmountRequest = async () => {
    let amount = localStorage.getItem('amountRequest')
    let amountData = JSON.parse(amount)


    try {
      const data = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/driver/${amountData?.driverId}`)


      const driverData = await axios.patch(`${process.env.REACT_APP_API_BASE_URL}/driver/${amountData?.driverId}`, {
        withdrawlAmount: (data?.data?.withdrawlAmount == 0) ? 0 : data?.data?.withdrawlAmount - amountData?.withdrawlAmount,
        redeemAmount: (data?.data?.status == 'Approved') ? data?.data?.redeemAmount : data?.data?.redeemAmount + amountData?.withdrawlAmount,
        status: "Approved"
      })

      localStorage.removeItem('amountRequest')
      // let d = await axios.patch(`${process.env.REACT_APP_API_BASE_URL}/notification/${amountData?._id}`, { read: true }).then((res) => {
      //   console.log("d is = ", d)
      // }
      // ).catch((err) => {
      //   console.log("err is ----- ", err)
      // })


      console.log("driverData => ", driverData)
      handleClose()
      handleCloseNoti1();
      toast.success('Reedeem Success!', { position: "bottom-right" });

    } catch (error) {
      toast.error(error?.response?.data?.message, {
        position: "bottom-right",
      });
    }
  }

  const handleAmountReject = async () => {
    let amount = localStorage.getItem('amountRequest')
    let amountData = JSON.parse(amount)


    try {
      // const data = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/driver/${amountData?.driverId}`)


      const driverData = await axios.patch(`${process.env.REACT_APP_API_BASE_URL}/driver/${amountData?.driverId}`, {
        status: "Rejected"
      });

      console.log('driverData= >>>>>>>>>>>>>>>>>>>>> ', driverData)
      localStorage.removeItem('amountRequest')

      // let d = await axios.patch(`${process.env.REACT_APP_API_BASE_URL}/notification/${amountData?._id}`, { read: true }).then((res) => {
      //   console.log("d is = ", d)
      // }
      // ).catch((err) => {
      //   console.log("err is ----- ", err)
      // })


      console.log("driverData => ", driverData)
      handleClose()
      handleCloseNoti1();
      toast.success('Reedeem Success!', { position: "bottom-right" });

    } catch (error) {
      toast.error(error?.response?.data?.message, {
        position: "bottom-right",
      });
    }
  }

  const handleCount = async () => {
    try {
      let data = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/notification/all/count`)
      setCount(data?.data)
    } catch (err) {
      toast.error(err?.response?.data?.message, {
        position: "bottom-right",
      });
    }
  }

  handleCount()

  useEffect(() => {
    fetchNotifications();
    const intervalId = setInterval(fetchNotifications, 60000);

    return () => {
      clearInterval(intervalId);
    };

  }, []);

  const [isOpen, setOpen] = useState<boolean>(false);
  const handleOpen = (): void => {
    setOpen(true);
  };
  const handleClose = (): void => {
    setOpen(false);
  };

  const handleData = async () => {
    try {
      let data = JSON.parse(localStorage.getItem('newOrder'))

      let d = await axios.patch(`${process.env.REACT_APP_API_BASE_URL}/notification/${data?._id}`, { read: true })
      localStorage.removeItem('newOrder');
      fetchNotifications()
      handleClose();
      navigate('/Orders')
      toast.success('All Orders!', { position: "bottom-right" });

    } catch (error) {
      toast.error(error?.response?.data?.message, {
        position: "bottom-right",
      });
    }
  }

  const handleRider = async () => {
    try {
      let data = JSON.parse(localStorage.getItem('rider'))

      let d = await axios.patch(`${process.env.REACT_APP_API_BASE_URL}/notification/${data?._id}`, { read: true })
      localStorage.removeItem('rider');
      fetchNotifications()
      handleClose()
      navigate('/Riders')
    } catch (error) {
      toast.error(error?.response?.data?.message, {
        position: "bottom-right",
      });
    }
  }

  const handlePreparing = async () => {
    try {
      let data = JSON.parse(localStorage.getItem('orderPreparing'))

      console.log("datadatadatadata", data)

      let d = await axios.patch(`${process.env.REACT_APP_API_BASE_URL}/notification/${data?._id}`, { read: true })
      localStorage.removeItem('orderPreparing');
      fetchNotifications()
      handleClose()
      navigate('/orders')
      toast.success('Order is preparing!', { position: "bottom-right" });

    } catch (error) {
      toast.error(error?.response?.data?.message, {
        position: "bottom-right",
      });
    }
  }


  const orderAccept = async () => {
    try {
      let data = JSON.parse(localStorage.getItem('orderAccept'))

      let d = await axios.patch(`${process.env.REACT_APP_API_BASE_URL}/notification/${data?._id}`, { read: true })
      localStorage.removeItem('orderAccept');
      fetchNotifications()
      handleClose()
      navigate('/orders')
      toast.success('Order is accepted!', { position: "bottom-right" });

    } catch (error) {
      toast.error(error?.response?.data?.message, {
        position: "bottom-right",
      });
    }
  }

  const handleVisibilityIcon = async (data) => {
    console.log("datadatadata =>>>>>>>>>>>> ", data)

    if (data?.type == "driverSignup") {
      await axios.patch(`${process.env.REACT_APP_API_BASE_URL}/notification/${data?._id}`, { read: true })
      handleClose();
      fetchNotifications();
      return navigate('/riders/pendingDriver');
    }

    if (data?.type == "orderDelivered") {
      // await axios.patch(`${process.env.REACT_APP_API_BASE_URL}/notification/${data?._id}`, { read: true })
      localStorage.setItem('paymentData', JSON.stringify(data))
      handleOpenNoti()
      return
    }

    await axios.patch(`${process.env.REACT_APP_API_BASE_URL}/notification/${data?._id}`, { read: true })
    handleClose();
    fetchNotifications();
    navigate(`/Orders/view/${data?.orderId}`)
  }

  return (
    <>
      <Tooltip arrow title="Notifications">
        <IconButton color="primary" ref={ref} onClick={handleOpen}>
          <NotificationsBadge
            badgeContent={count ? count : 0}
            anchorOrigin={{
              vertical: 'top', horizontal: 'right'
            }}>
            <NotificationsActiveTwoToneIcon />
          </NotificationsBadge>
        </IconButton>
      </Tooltip>
      <Popover className="popBox" style={{ borderRadius: 5 }}
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <Box className="Notifcation-section-box"
          sx={{ p: 2 }}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="h5">Notifications</Typography>
        </Box>
        <Divider />
        <List sx={{ p: 0 }} className='list-box-1'>
          <ListItem className="Notification-list"
            sx={{ p: 2, minWidth: 350, display: { xs: 'block', sm: 'flex' } }}
          >
            <Box flex="1">
              {notficationdata?.map((data) => (

                <>
                  {
                    data?.read == false ? (
                      <Box display="flex" justifyContent="space-between" className="text-box1">
                        <div className="View-box">
                          <Typography sx={{ m: 0.7 }}
                            className="Notification-text"
                            component="span"
                            variant="body2">
                            {data?.message}
                          </Typography>

                          <Tooltip title="View" arrow>
                            <IconButton
                              onClick={(e) => { handleVisibilityIcon(data) }}
                              sx={{
                                '&:hover': {
                                  background: theme.colors.primary.lighter
                                },
                                color: theme.palette.primary.main
                              }}
                              color="inherit"
                              size="small"

                            >
                              <VisibilityIcon className='view-icon' fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </div>

                        <Typography variant="body2" className="Notification-text2">
                          {formatDistance(new Date(data?.createdAt), new Date(), {
                            addSuffix: true
                          })}
                        </Typography>

                      </Box >
                    ) : null
                  }

                </>
              ))}
            </Box>
          </ListItem>
        </List>
      </Popover >
      <Modal
        open={openNoti}
        onClose={handleCloseNoti}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div style={{ margin: 9 }}>
            <TextField id="outlined-required" label="Payment"
              name="payment" type="number" onChange={(e: any) => setpaymentAmount(e.target.value)}
            />
          </div>

          <div style={{ margin: 9, width: '100%' }}>
            <Button variant="contained" type="submit" onClick={handlePayment} disabled={buttonDisabled}>
              Submit
            </Button>
            <Button onClick={() => navigate('/')}>
              Back To Dashboard
            </Button>
          </div>


        </Box>
      </Modal>

      <Modal
        open={openNoti1}
        onClose={handleCloseNoti1}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>

          <div style={{ margin: 9, width: '100%' }}>
            <Button variant="contained" onClick={handleAmountRequest}>
              Accept
            </Button>

            <Button variant="contained" style={{ margin: 9 }} onClick={handleAmountReject}>
              Reject
            </Button>

            {/* <Button variant="contained" style={{ margin: 9 }} onClick={() => navigate('/')} >
              Cancel
            </Button> */}

          </div>


        </Box>
        {/* <Box sx={style}>
          <div style={{ margin: 9 }}>
            <TextField
              id="outlined-required"
              label="Payment Amount"
              name="payment"
              type="number"
              onChange={(e: any) => setpaymentAmount(e.target.value)}
            />

          </div>

          <div style={{ margin: 9, width: '100%' }}>
            <Button variant="contained" type="submit" onClick={handlePayment}>
              Submit
            </Button>
            <Button onClick={() => navigate('/')}>
              Back To Dashboard
            </Button>
          </div>


        </Box> */}
      </Modal>

    </>
  );
};

export default HeaderNotifications;
