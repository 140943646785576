import { Box, Button, Radio, RadioGroup, FormLabel, FormControl, FormControlLabel, TextField, InputLabel, Select } from "@mui/material"
import { useEffect, useState } from "react"
import { Helmet } from "react-helmet-async";
import axios from 'axios';
import AWS from 'aws-sdk';

import './AddRiders.css';
import 'react-toastify/dist/ReactToastify.css';
import { toast, ToastContainer } from 'react-toastify';

import { useNavigate } from 'react-router-dom';

interface FormData {
  firstName: string;
  lastName: string;
  email: string;
  mobile: string;
  age: string;
  profileImage: string;
  gender: any;
  uanImage: any;
  drivingLienceImage: any;
}

const initialFormData: FormData = {
  firstName: '', lastName: '', email: '', mobile: '', age: '', profileImage: '', gender: '',
  uanImage: '', drivingLienceImage: ''
};


//storing image in bucket
AWS.config.update({
  accessKeyId: 'AKIARWTYEXZGV5I6UJQJ',
  secretAccessKey: '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',
  region: 'ap-south-1',
});



const AddRider = () => {

  const s3 = new AWS.S3();
  const navigate = useNavigate();

  const [submitting, setSubmitting] = useState(false)
  const [addRiderData, setAddRiderData] = useState(initialFormData);
  const [errors, setErrors] = useState<Partial<FormData>>({});
  const [imageErr, setImageErr] = useState(false);
  const [image, setImage] = useState(null)

  const [uanImageErr, setUanImageErr] = useState(false);
  const [uanImage, setUanImage] = useState(null)


  const [drivingLienceImageErr, setDrivingLienceImageErr] = useState(false);
  const [drivingLienceImage, setDrivingLienceImage] = useState(null)

  const validateForm = () => {
    const errors: Partial<FormData> = {};

    if (!addRiderData?.firstName.trim()) {
      errors.firstName = 'First Name is required';
    }

    if (!addRiderData?.lastName.trim()) {
      errors.lastName = 'Last Name is required';
    }

    if (!addRiderData.email) {
      errors.email = 'Email is required';
    } else {

      const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
      if (!emailRegex.test(addRiderData.email)) {
        errors.email = 'Invalid email address';
      }
    }
    // if (!addRiderData?.mobile.trim()) {
    //   errors.mobile = 'Mobile No. is required';
    // } else if (!/^\d+$/.test(addRiderData.mobile)) {
    //   errors.mobile = 'Mobile No. should contain only numbers';
    // }
    if (!addRiderData?.mobile.trim()) {
      errors.mobile = 'Mobile No. is required';
    } else if (!/^\d{10}$/.test(addRiderData.mobile)) {
      errors.mobile = 'Please Provide Valid Phone Number';
    }


    if (!addRiderData?.age.trim()) {
      errors.age = 'Age is required';
    } else if (!/^\d+$/.test(addRiderData.age)) {
      errors.age = 'Age should contain only numbers';
    }

    // if (!addRiderData?.profileImage.trim()) {
    //   errors.profileImage = 'Image is required';
    // }
    if (!addRiderData?.gender.trim()) {
      errors.gender = 'Gender is required';
    }
    if (!addRiderData?.uanImage.trim()) {
      errors.uanImage = 'Required Field';
    }
    // drivingLienceImage
    if (!addRiderData?.drivingLienceImage.trim()) {
      errors.drivingLienceImage = 'Required Field';
    }
    return errors;
  };

  async function handleImageFile(e) {
    setImageErr(false);
    setImage(e.target.files[0]?.name)

    if (e.target.files[0]?.name) {
      const params = {
        Bucket: 'suribucket-v2',
        Key: `${Date.now()}.${e?.target?.files[0]?.name}`,
        Body: e?.target?.files[0],
        ACL: 'public-read-write'
      };

      try {
        const { Location } = await s3.upload(params).promise();

        setAddRiderData(prevData => ({
          ...prevData,
          profileImage: Location
        }));


      } catch (error) {
        toast.error(error, { position: "bottom-right" });
      }
    }
  }


  async function handleUANChange(e) {
    setUanImageErr(false);
    setUanImage(e.target.files[0]?.name)

    if (e.target.files[0]?.name) {
      const params = {
        Bucket: 'suribucket-v2',
        Key: `${Date.now()}.${e?.target?.files[0]?.name}`,
        Body: e?.target?.files[0],
        ACL: 'public-read-write'
      };

      try {
        const { Location } = await s3.upload(params).promise();

        setAddRiderData(prevData => ({
          ...prevData,
          uanImage: Location  // Set the URL of the uploaded image
        }));

      } catch (error) {
        toast.error(error, { position: "bottom-right" });
      }
    }
  }


  async function handleDrivingChange(e) {
    setDrivingLienceImageErr(false);
    setDrivingLienceImage(e.target.files[0]?.name)

    if (e.target.files[0]?.name) {
      const params = {
        Bucket: 'suribucket-v2',
        Key: `${Date.now()}.${e?.target?.files[0]?.name}`,
        Body: e?.target?.files[0],
        ACL: 'public-read-write'
      };

      try {
        const { Location } = await s3.upload(params).promise();
        setDrivingLienceImageErr(false)
        setAddRiderData(prevData => ({
          ...prevData,
          drivingLienceImage: Location
        }));


      } catch (error) {
        toast.error(error, { position: "bottom-right" });
      }
    }
  }


  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setAddRiderData({ ...addRiderData, [name]: value });
    setErrors((prevErrors) => ({ ...prevErrors, [name]: undefined }));
  };


  const [error, setError] = useState('')

  const handleRiderSubmit = async (e) => {
    try {
      e.preventDefault();
      const errors = validateForm();
      if (Object.keys(errors).length === 0) {
        setSubmitting(true);
        const response = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/driver/create`, {
          ...addRiderData,
          firstName: addRiderData?.firstName,
          mobile: addRiderData?.mobile,
          lastName: addRiderData?.lastName,
          gender: addRiderData?.gender,
          age: addRiderData?.age,
          email: addRiderData?.email,
          uan: addRiderData?.uanImage,
          licence: addRiderData?.drivingLienceImage,
          status: 'Approved'
        });

        toast.success('Rider Created Successfully!', { position: "bottom-right" });
        setAddRiderData(initialFormData);
        navigate('/riders/approvedDriver')
      } else {
        setErrors(errors);
        return;
      }
    } catch (err) {
      setSubmitting(false)
      toast.error(err?.response?.data?.message, { position: "bottom-right" });
    }
  }
  return (
    <>
      <Helmet>
        <title>Add Rider</title>
      </Helmet>

      <form
        onSubmit={handleRiderSubmit}
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          gap: '10',
          background: '#fff',
          padding: 15,
          margin: 30,
          borderRadius: 10
        }}
      >
        <div style={{ margin: 4, width: '100%' }}>
          <p
            style={{
              margin: 4,
              width: '100%',
              color: '#000',
              fontWeight: 600,
              marginBottom: '30px'
            }}
          >
            Add Rider
          </p>
        </div>
        <div style={{display:'flex'}}>
          <div style={{ margin: 9 }}>
            <TextField
              id="outlined-required"
              label="First Name"
              name="firstName"
              value={addRiderData?.firstName}
              onChange={handleInputChange}
            />
            {errors?.firstName && (
              <span style={{ color: 'red', display: 'flex', paddingTop: '10px' }}>
                {errors?.firstName}
              </span>
            )}
          </div>
          <div style={{ margin: 9 }}>
            <TextField
              id="outlined-required"
              label="Last Name"
              name="lastName"
              value={addRiderData?.lastName}
              onChange={handleInputChange}
            />
            {errors?.lastName && (
              <span style={{ color: 'red', display: 'flex', paddingTop: '10px' }}>
                {errors?.lastName}
              </span>
            )}
          </div>
          <div style={{ margin: 9 }}>
            <TextField
              id="outlined-required"
              label="Email"
              name="email"
              value={addRiderData?.email}
              onChange={handleInputChange}
            />
            {errors?.email && (
              <span style={{ color: 'red', display: 'flex', paddingTop: '10px' }}>
                {errors?.email}
              </span>
            )}
          </div>
          <div style={{ margin: 9 }}>
            <TextField
              id="outlined-required"
              label="Mobile"
              name="mobile"
              value={addRiderData?.mobile}
              onChange={handleInputChange}
            />
            {errors?.mobile && (
              <span style={{ color: 'red', display: 'flex', paddingTop: '10px' }}>
                {errors?.mobile}
              </span>
            )}

          </div>
        </div>


        <div style={{display:'flex'}}>
          <div style={{ margin: 9 }}>
            <TextField
              id="outlined-required"
              label="Age"
              name="age"
              value={addRiderData?.age}
              onChange={handleInputChange}
            />
            {errors?.age && (
              <span style={{ color: 'red', display: 'flex', paddingTop: '10px' }}>
                {errors?.age}
              </span>
            )}
          </div>

          <FormControl component="fieldset" style={{ margin: 8 }}>
            <FormLabel component="legend">Gender</FormLabel>
            <RadioGroup
              row
              aria-label="gender"
              name="gender"
              value={addRiderData?.gender}
              onChange={handleInputChange}
            >
              <FormControlLabel
                value="female"
                control={<Radio />}
                label="Female"
              />
              <FormControlLabel value="male" control={<Radio />} label="Male" />
              <FormControlLabel value="other" control={<Radio />} label="Other" />
            </RadioGroup>
            {errors.gender && (
              <span style={{ color: 'red', display: 'flex', paddingTop: '10px' }}>
                {errors?.gender}
              </span>
            )}
          </FormControl>

        </div>

        <div style={{display:'flex'}}>

        <div style={{ margin: 12 }} >
            <span> Profile Image</span>
            <div className="SubmitBtn">
              <input type="file" onChange={handleImageFile} />
            </div>

          </div>

          <div style={{ margin: 12 }}>
            <span> Driving Licence</span>
            <div className="SubmitBtn">
              <input type="file" onChange={handleUANChange} />
            </div>
            {errors.drivingLienceImage && (
              <span style={{ color: 'red', display: 'flex', paddingTop: '10px' }}>
                {errors.drivingLienceImage}
              </span>
            )}
          </div>

          <div style={{ margin: 12 }}>
            <span> UAN  Number</span>
            <div className="SubmitBtn">
              <input type="file" onChange={handleDrivingChange} />
            </div>
            {errors.uanImage && (
              <span style={{ color: 'red', display: 'flex', paddingTop: '10px' }}>
                {errors.uanImage}
              </span>
            )}
          </div>
        </div>


        <div style={{ margin: 9, width: '100%' }}>
          <Button variant="contained" type="submit" disabled={submitting == true}>
            Submit
          </Button>
        </div>
      </form>


    </>
  )
}
export default AddRider